import { Component } from 'react';

import isEqual from 'lodash/isEqual';

import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import FormField from '../../SearchControls/FormField';
import BpkBreakpoint, { BREAKPOINTS } from '../BpkBreakpoint';

import GuestsRoomsChildrenPopover from './GuestsRoomsChildrenPopover';
import GuestsRoomsChildrenPortal from './GuestsRoomsChildrenPortal';

import type { Maybe } from 'common-types/types/components';

import STYLES from './GuestsRoomsChildrenSelect.scss';

type Props = {
  adults: number;
  rooms: number;
  childrenAges: string[];
  getGuestsRoomsChildren: Function;
  label?: string;
  lightLabel?: boolean;
  hideLabel?: boolean;
  collapsed?: boolean;
  className?: Maybe<string>;
  stayEditClassName?: string;
};

type State = {
  isOpen: boolean;
  rooms: number;
  adults: number;
  childrenAges: string[];
};

const defaultProps = {
  lightLabel: false,
  collapsed: false,
  label: undefined,
  className: undefined,
  stayEditClassName: undefined,
  hideLabel: false,
};

const cls = cssModules(STYLES);

class GuestsRoomsChildrenSelect extends Component<Props, State> {
  static defaultProps = defaultProps;

  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
      rooms: props.rooms,
      adults: props.adults,
      childrenAges: props.childrenAges,
    };
  }

  onOpen = () => {
    window.name = 'DVSearchModalIsOpen';
    const { adults, childrenAges, rooms } = this.props;
    this.setState({
      isOpen: true,
      rooms,
      adults,
      childrenAges,
    });
  };

  onClose = (shouldUpdate: boolean = true) => {
    this.setState({
      isOpen: false,
    });
    if (shouldUpdate) {
      const { adults, childrenAges, rooms } = this.state;
      this.props.getGuestsRoomsChildren(rooms, adults, childrenAges);
    } else {
      const { adults, childrenAges, rooms } = this.props;
      this.setState({
        rooms,
        childrenAges,
        adults,
      });
    }
  };

  onGuestsRoomsChildrenChanged = (
    rooms: number,
    adults: number,
    childrenAges: string[],
  ) => {
    if (rooms !== this.state.rooms) {
      this.setState({
        rooms,
      });
    }

    if (adults !== this.state.adults) {
      this.setState({
        adults,
      });
    }

    if (!isEqual(childrenAges, this.state.childrenAges)) {
      this.setState({
        childrenAges,
      });
    }
  };

  render() {
    const {
      adults,
      childrenAges,
      className,
      collapsed,
      hideLabel,
      label,
      lightLabel,
      rooms,
      stayEditClassName,
    } = this.props;
    const {
      adults: adultsState,
      childrenAges: childrenAgesState,
      isOpen,
      rooms: roomState,
    } = this.state;
    const isApplyButtonDisabled =
      rooms === roomState &&
      adults === adultsState &&
      isEqual(childrenAges, childrenAgesState);
    const guestsRoomsFieldId = `guests-rooms${collapsed ? '-collapsed' : ''}`;

    return (
      <div className={cls('GuestsRoomsChildrenSelect', className)}>
        <FormField
          className={cls(
            'GuestsRoomsChildrenSelect__formField',
            stayEditClassName,
          )}
          fieldId={guestsRoomsFieldId}
          label={label}
          lightLabel={lightLabel}
          hideLabel={hideLabel}
        >
          <BpkBreakpoint query={BREAKPOINTS.MOBILE}>
            {(isMobile: boolean) =>
              isMobile ? (
                <GuestsRoomsChildrenPortal
                  adults={adults}
                  rooms={rooms}
                  childrenAges={childrenAges}
                  onlyShowGuestsNumber={hideLabel}
                  isApplyButtonDisabled={isApplyButtonDisabled}
                  guestsRoomsFieldId={guestsRoomsFieldId}
                  onOpen={this.onOpen}
                  onClose={this.onClose}
                  onGuestsRoomsChildrenChanged={
                    this.onGuestsRoomsChildrenChanged
                  }
                />
              ) : (
                <GuestsRoomsChildrenPopover
                  isOpen={isOpen}
                  adults={adults}
                  rooms={rooms}
                  childrenAges={childrenAges}
                  guestsRoomsFieldId={guestsRoomsFieldId}
                  onOpen={this.onOpen}
                  onClose={this.onClose}
                  onGuestsRoomsChildrenChanged={
                    this.onGuestsRoomsChildrenChanged
                  }
                />
              )
            }
          </BpkBreakpoint>
        </FormField>
      </div>
    );
  }
}

export default GuestsRoomsChildrenSelect;

type Parameters = {
  adults: number;
  rooms: number;
  childrenAges?: string[];
  translatePlural: Function;
};

const getGuestsRoomsChildrenValue = ({
  adults,
  childrenAges,
  rooms,
  translatePlural,
}: Parameters) => {
  const childrenLength = childrenAges && childrenAges.length;

  let adultsLabel = translatePlural(
    'HotelStay_label_adults_',
    adults,
    '0',
    null,
    9,
    'X',
  );

  if (adults > 9) {
    adultsLabel = [
      adultsLabel[1].toString().substring(0, 2),
      adultsLabel[2],
    ].join('');
  }

  const roomsLabel = translatePlural(
    'SelectionSummary_label_rooms',
    rooms,
    'number',
  );

  if (!childrenLength) {
    return `${adultsLabel}, ${roomsLabel}`;
  }

  const childrenLabel = translatePlural(
    'HotelStay_label_children_',
    childrenLength,
    'number',
  );

  return `${adultsLabel}, ${childrenLabel}, ${roomsLabel}`;
};

export default getGuestsRoomsChildrenValue;

import { Component } from 'react';

import isEqual from 'lodash/isEqual';
import range from 'ramda/es/range';

import {
  withButtonAlignment,
  withRtlSupport,
} from '@skyscanner/backpack-web/bpk-component-icon';
import LgAdultIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/adult';
import LgRoomIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/room';
import SmAdultIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/adult';
import BpkNudger from '@skyscanner/backpack-web/bpk-component-nudger';
import { BpkSectionListItem } from '@skyscanner/backpack-web/bpk-component-section-list';
import BpkSelect from '@skyscanner/backpack-web/bpk-component-select';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import { HOTEL_BOOKING_LIMITS } from '../../../../../constants';
import { withI18n } from '../../../../services/i18n';

import type { I18nShape } from '../../../../services/i18n';

import STYLES from './GuestsRoomsChildren.module.scss';

type Props = {
  i18n: I18nShape;
  adults: number;
  rooms: number;
  childrenAges: string[];
  onGuestsRoomsChildrenChanged: Function;
  iconAppend?: boolean;
};

type State = {
  rooms: number;
  adults: number;
  childrenAges: string[];
};

const defaultProps = { iconAppend: true };

const AlignedLgAdultIcon = withButtonAlignment(withRtlSupport(LgAdultIcon));
const AlignedLgRoomIcon = withButtonAlignment(withRtlSupport(LgRoomIcon));

class GuestsRoomsChildren extends Component<Props, State> {
  static defaultProps = defaultProps;

  constructor(props: Props) {
    super(props);
    this.state = {
      rooms: props.rooms,
      adults: props.adults,
      childrenAges: props.childrenAges,
    };
  }

  // @ts-ignore we need to get preStates but no prevProps
  componentDidUpdate(prevProps: Props, preStates: State) {
    const { adults, childrenAges, rooms } = this.state;
    if (!isEqual(preStates, this.state))
      this.props.onGuestsRoomsChildrenChanged(rooms, adults, childrenAges);
  }

  roomsHandleChange = (rooms: number) => {
    const { adults } = this.state;
    if (rooms > adults) {
      this.setState({ adults: rooms });
    }
    this.setState({ rooms });
  };

  adultsHandleChange = (adults: number) => {
    const { rooms } = this.state;
    if (adults < rooms) {
      this.setState({ rooms: adults });
    }
    this.setState({ adults });
  };

  childrenHandleChange = (childrenAges: any) => {
    const [...newChildren] = this.state.childrenAges;
    if (childrenAges > this.state.childrenAges.length) {
      newChildren.push('0');
    } else if (childrenAges < this.state.childrenAges.length) {
      newChildren.pop();
    }

    this.setState({
      childrenAges: newChildren,
    });
  };

  childrenAgeHandleChange = (value: any, index: number) => {
    const { childrenAges } = this.state;
    const newChildren = childrenAges.map((v, i) => {
      if (i === index) {
        return value;
      }
      return v;
    });

    this.setState({
      childrenAges: newChildren,
    });
  };

  render() {
    const { adults, childrenAges = [], rooms } = this.state;
    const { translate, translatePlural } = this.props.i18n;
    const { iconAppend } = this.props;
    const childrenAgeLen = childrenAges.length;

    const rangedOptions = range(
      HOTEL_BOOKING_LIMITS.MIN_CHILD_AGE,
      HOTEL_BOOKING_LIMITS.MAX_CHILD_AGE,
    );

    return (
      <div className={STYLES.GuestsRoomsChildren}>
        <BpkSectionListItem>
          {iconAppend && <AlignedLgAdultIcon />}
          <label
            className={
              iconAppend
                ? STYLES.GuestsRoomsChildren__sectionListTitle
                : STYLES.GuestsRoomsChildren__sectionListTitleOnlyText
            }
            htmlFor="adults"
          >
            {translate('HotelStay_label_Adults')}
          </label>
          <BpkNudger
            id="adults"
            min={HOTEL_BOOKING_LIMITS.MIN_ADULTS}
            max={HOTEL_BOOKING_LIMITS.MAX_ADULTS}
            value={adults}
            onValueChange={this.adultsHandleChange}
            decreaseButtonLabel="Decrease"
            increaseButtonLabel="Increase"
          />
        </BpkSectionListItem>

        <BpkSectionListItem
          className={STYLES.GuestsRoomsChildren__sectionListChildren}
        >
          {iconAppend && (
            <SmAdultIcon className={STYLES.GuestsRoomsChildren__smallAdult} />
          )}
          <label
            className={
              iconAppend
                ? STYLES.GuestsRoomsChildren__sectionListTitle
                : STYLES.GuestsRoomsChildren__sectionListTitleOnlyText
            }
            htmlFor="children"
          >
            {translate('HotelStay_label_Children')}
          </label>
          <BpkNudger
            id="children"
            min={HOTEL_BOOKING_LIMITS.MIN_CHILDREN}
            max={HOTEL_BOOKING_LIMITS.MAX_CHILDREN}
            value={childrenAgeLen}
            onValueChange={this.childrenHandleChange}
            decreaseButtonLabel="Decrease"
            increaseButtonLabel="Increase"
          />
        </BpkSectionListItem>

        {childrenAgeLen > 0 && (
          <div>
            <BpkText
              className={STYLES.GuestsRoomsChildren__childAgeTitle}
              tagName="p"
              textStyle={TEXT_STYLES.subheading}
            >
              {translate('HotelStay_label_Ages_of_children')}
            </BpkText>
            <section className={STYLES.GuestsRoomsChildren__popover}>
              {childrenAges.map((item, index) => (
                <BpkSelect
                  className={STYLES['GuestsRoomsChildren__popover--selectAge']}
                  key={`${item}-${index.toString()}`}
                  id={`childrenAges-${index}`}
                  name="childrenAges"
                  value={item}
                  onChange={(e: any) =>
                    this.childrenAgeHandleChange(e.target.value, index)
                  }
                >
                  {rangedOptions.map((n: number) => (
                    <option value={n} key={n}>
                      {n < 1
                        ? translate('HotelStay_label_year_old_0')
                        : translatePlural(
                            'HotelStay_label_year_old_',
                            n,
                            '0',
                            {},
                            1,
                            'X',
                          )}
                    </option>
                  ))}
                </BpkSelect>
              ))}
            </section>
          </div>
        )}

        <BpkSectionListItem
          className={STYLES.GuestsRoomsChildren__sectionListRoom}
        >
          {iconAppend && <AlignedLgRoomIcon />}
          <label
            className={
              iconAppend
                ? STYLES.GuestsRoomsChildren__sectionListTitle
                : STYLES.GuestsRoomsChildren__sectionListTitleOnlyText
            }
            htmlFor="rooms"
          >
            {translate('SearchControls_label_Rooms')}
          </label>
          <BpkNudger
            id="rooms"
            min={HOTEL_BOOKING_LIMITS.MIN_ROOMS}
            max={HOTEL_BOOKING_LIMITS.MAX_ROOMS}
            value={rooms}
            onValueChange={this.roomsHandleChange}
            decreaseButtonLabel="Decrease"
            increaseButtonLabel="Increase"
          />
        </BpkSectionListItem>
      </div>
    );
  }
}

export default withI18n(GuestsRoomsChildren);

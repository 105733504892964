import { Component, createRef } from 'react';
import type { RefObject } from 'react';

import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import FilterItem from './FilterItem';

import type { Item } from './types';

import STYLES from './Preference.scss';

type Props = {
  className?: string;
  onGetPreference: Function;
  freeCancellation?: boolean;
};

type State = {
  [key: string]: any;
};

const defaultProps = {
  className: undefined,
  freeCancellation: false,
};

const cls = cssModules(STYLES);

const REPEATED_FILTERS_KEY = ['stars'];

const filters: any = [
  {
    title: {
      id: 'popular_filters',
      key: 'preference_label_popular_filters',
      value: 'Popular filters:',
      filterClassName: 'Preference__title',
      tip: null,
    },
  },
  {
    id: 'cancellation',
    key: 'CancellationPolicy_freeCancellation',
    value: 'free_cancellation',
    filterClassName: 'Preference__item',
    tip: 'preference_label_show_me_hotels_with_free_cancellation',
  },
  {
    stars: [
      {
        id: 'stars',
        key: 'Filters_Stars_label_stars4',
        value: 4,
        filterClassName: 'Preference__item',
        tip: 'preference_label_show_me_4_star_hotels',
      },
      {
        id: 'stars',
        key: 'Filters_Stars_label_stars3',
        value: 3,
        filterClassName: 'Preference__item',
        tip: 'preference_label_show_me_3_star_hotels',
      },
    ],
  },
];

class Preference extends Component<Props, State> {
  static defaultProps = defaultProps;

  allFilters: any;

  // eslint-disable-next-line react/no-unused-class-component-methods
  target: RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);

    this.state = {};
    this.allFilters = {};
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.target = createRef();
  }

  // eslint-disable-next-line class-methods-use-this
  getStateKey = (id: string, value: string) => `${id}_${value}`;

  // eslint-disable-next-line class-methods-use-this
  getSelectedFilters = (allFilters: any) => {
    const selectedFilters: any = {
      urlFilters: {},
      miniEventFilters: {},
    };
    const allFilterKeys = Object.keys(allFilters);

    allFilterKeys.forEach((key) => {
      if (allFilters[key].length) {
        const stringValue = allFilters[key].join();
        selectedFilters.urlFilters[key] = stringValue;

        if (REPEATED_FILTERS_KEY.includes(key)) {
          selectedFilters.miniEventFilters[key] = allFilters[key];
        } else {
          selectedFilters.miniEventFilters[key] = stringValue.toUpperCase();
        }
      }
    });

    return selectedFilters;
  };

  togglePreference = (id: string, value: string) => {
    const { onGetPreference } = this.props;

    this.setState((prevState: any) => {
      const key = this.getStateKey(id, value);
      if (!prevState[key]) {
        if (this.allFilters[id]) {
          this.allFilters[id].push(value);
        } else {
          this.allFilters[id] = [value];
        }
      } else {
        this.allFilters[id].splice(this.allFilters[id].indexOf(value), 1);
      }

      const selectedFilters = this.getSelectedFilters(this.allFilters);
      const { urlFilters } = selectedFilters;
      const urlFilterKeys = Object.keys(urlFilters);
      if (urlFilterKeys.length) {
        onGetPreference(selectedFilters);
      }

      return { [key]: !prevState[key] };
    });
  };

  render() {
    const { className, freeCancellation } = this.props;

    return (
      <section className={cls('Preference', className)}>
        {filters.map((item: Item) => {
          const { id, stars, value } = item;
          const key = this.getStateKey(id, value);

          if (stars) {
            return (
              <div key="stars">
                {stars.map((star) => (
                  <FilterItem
                    key={`${star.id}-${star.value}`}
                    item={star}
                    onChange={() => this.togglePreference(star.id, star.value)}
                    checked={this.state[this.getStateKey(star.id, star.value)]}
                    disabled={false}
                  />
                ))}
              </div>
            );
          }

          return (
            <FilterItem
              key={`${id}-${value}`}
              item={item}
              onChange={() => this.togglePreference(id, value)}
              checked={
                this.state[key] ||
                (freeCancellation && key === 'cancellation_free_cancellation')
              }
              disabled={
                !!(freeCancellation && key === 'cancellation_free_cancellation')
              }
            />
          );
        })}
      </section>
    );
  }
}

export default Preference;

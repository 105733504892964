const XSELL_MAP = 'XSellMap';
const GOOGLE_HPA = 'GoogleHpa';
const FLIGHT_PQS = 'FLIGHT_PQS';
const FLIGHT_DBOOK_COUPON = 'FlightDBookCoupon';
const FLIGHT_DBOOK_EMAIL_LOW = 'FlightDBookEmailLow';
const FLIGHT_DBOOK_EMAIL_MIDDLE = 'FlightDBookEmailMiddle';
const FLIGHT_DAYVIEW_ADS = 'FlightsDayviewAds';
const FLIGHT_DAIVIEW_OC = 'FlightsDayViewOC';
const LIFE_CYCLE_MARKET_DIRECT_DISCOUNT_EMAIL = 'LCMDirectDiscountEmail';

const DIRECT_DISCOUNT_PAGES = [
  'PAGE_TYPE_UNSET',
  'DAYVIEW',
  'HOTEL_DETAIL',
  'FLIGHT_DAYVIEW',
  'HOTEL_DAYVIEW',
  'FLIGHT_SUCCESSFUL_END_PAGE',
  'FLIGHT_CHECKOUT',
];

const SECRET_DEALS_EXCLUDE_SOURCE = [
  XSELL_MAP,
  FLIGHT_PQS,
  FLIGHT_DBOOK_EMAIL_LOW,
  FLIGHT_DBOOK_EMAIL_MIDDLE,
  GOOGLE_HPA,
];

const DIRECT_DISCOUNT_SOURCE = [...DIRECT_DISCOUNT_PAGES, FLIGHT_PQS];

const EMAIL_COUPON_SOURCE = [FLIGHT_DBOOK_EMAIL_LOW, FLIGHT_DBOOK_EMAIL_MIDDLE];

const FLIGHT_OTHER_SOURCE = [
  FLIGHT_DBOOK_COUPON,
  XSELL_MAP,
  FLIGHT_DAYVIEW_ADS,
  FLIGHT_DAIVIEW_OC,
];

const isFromFlightDirectDiscount = (source) =>
  DIRECT_DISCOUNT_SOURCE.includes(source);

const isFromDirectDiscountPage = (source) =>
  DIRECT_DISCOUNT_PAGES.includes(source);

const isFromFlightDBookEmail = (source) => EMAIL_COUPON_SOURCE.includes(source);

const isFromLifeCycleMarketDirectDiscountEmail = (source) =>
  source === LIFE_CYCLE_MARKET_DIRECT_DISCOUNT_EMAIL;

const isFromFlightsDayviewAds = (source) => source === FLIGHT_DAYVIEW_ADS;

const isFromGoogleHPA = (source) => source === GOOGLE_HPA;

const isExcludeSecretDeals = (source) =>
  SECRET_DEALS_EXCLUDE_SOURCE.includes(source);

const trafficSource = ({ isBananaReferral, source }) => {
  if (isBananaReferral) {
    return 'Banana';
  }

  if (
    isFromFlightDBookEmail(source) ||
    isFromFlightDirectDiscount(source) ||
    isFromFlightsDayviewAds(source) ||
    isFromLifeCycleMarketDirectDiscountEmail(source) ||
    isFromGoogleHPA(source) ||
    FLIGHT_OTHER_SOURCE.includes(source)
  ) {
    return source;
  }

  return 'Unknown';
};

const getUtmSource = (utmSource) => {
  const regExp = /^([a-z-A-Z_]+)$/;
  if (utmSource && regExp.test(utmSource)) {
    return utmSource;
  }
  return null;
};

export {
  isFromFlightDirectDiscount,
  trafficSource,
  isFromDirectDiscountPage,
  isFromGoogleHPA,
  isExcludeSecretDeals,
  getUtmSource,
};

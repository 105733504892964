import type { DeviceShape } from '../types';

const getDeviceType = (device?: DeviceShape) => {
  if (device?.isMobilePhone) {
    return 'mobile';
  }
  if (device?.isTablet) {
    return 'tablet';
  }
  return 'desktop';
};

export default getDeviceType;

import { COMPONENT_ACTION } from '../../../constants';
import getDeviceType from '../../components-search-controls-adjacent/get-device-type';
import logger from '../../services/logger';
import { getCurrentQueryParam } from '../../services/url/url';
import GaClient from '../ga-client';
import { ACTION_TYPE, BOOKING_STATUS_MAPPING } from '../minievents/constants';
import {
  buildAdditionalInfoPageLoadMessage,
  buildAdditionalInfoSearchStartedMessage,
  buildAdditionalInfoSearchFinishedMessage,
  buildAdditionalInfoDbookOrMetaButtonClicked,
  buildAdditionalInfoSearchResultSelectedMessage,
  buildAdditionalInfoPricesLoadedMessage,
  buildDayViewFiltersUsedMessage,
  buildDayViewSortUsedMessage,
  buildAdditionalInfoPriceClickedInfo,
  buildAdditionalInfoSearchResultLoadedMessage,
  buildAdditionInfoBookingProcessState,
  buildAdditionInfoBookingPerformed,
  PRICES_LOADED_TYPES,
  buildAdditionalInfoHotelPriceStatusMessage,
  buildAdditionalInfoUnifiedPaymentPlatformMessage,
  buildAdditionalInfoPreCheckState,
} from '../minievents/hotels-action';

import FunnelEventsClient from './funnel-events-client';
import StatsDClient from './statsd-client';

import type { DeviceShape, Maybe } from '../../types';
import type {
  MetricsType,
  StatsDClientType,
  StatsDProps,
  GaClientType,
  ElementEventTrackerReturn,
  OneOfTag,
  ObserverClientReturn,
  FunnelEventsClientType,
  UserSearched,
} from '../types';
import type {
  HotelCardShape,
  CugPriceShape,
} from 'common-types/types/hotels-components/types';

type Props = {
  statsD: StatsDProps;
  deviceInfo: DeviceShape;
  pageName: string;
  region: string;
  trafficSource: string;
  elementEventTracker: ElementEventTrackerReturn;
  observerClient: ObserverClientReturn;
};

type PriceSelectedProps = {
  dBook: boolean;
  currency?: string;
  isLowest: boolean;
  isFiltered: boolean;
  isMapped?: boolean;
  rateFilterValues: string;
  roomFilterValue: string;
  position: number;
  partnerId: string;
  price?: number;
  hasImage: boolean;
  roomName: string;
  positiveAmenities: string;
  clickArea?: string;
};

type PaymentProps = {
  partnerId: string;
  type?: string;
};

type ThreeDS2PaymentProps = {
  partnerId: string;
  market: string;
  currency: string;
};

type BabysharkPrecheckProps = {
  env: string;
  issuercc?: string;
  isSupported?: boolean;
} & ThreeDS2PaymentProps;

type CreateCardProps = {
  statusCode: string;
};

type TopRateInfo = {
  price: number;
  partnerId: string;
  baseRoomType?: string;
  roomId?: string;
  rateId?: string;
  isMappedForTopFive: boolean;
};

type PriceChangedInfo = {
  expectedPrice: number;
  diffAmount: number;
  type: string;
  currency: string;
};

/**
 * Application Metrics
 * Stable Events that can be logged to multiple sources
 * */

class ApplicationMetrics implements MetricsType {
  ga: GaClientType;

  statsd: StatsDClientType;

  funnelEvents: FunnelEventsClientType;

  deviceInfo: DeviceShape;

  pageName: string;

  region: string;

  trafficSource: string;

  elementEventTracker: ElementEventTrackerReturn;

  firedEvents: { [eventName: string]: boolean };

  HOTELS_SEARCH_CONTROLS_CLICKED_EVENT = 'hotels-search-controls-clicked';

  HOTELS_SEARCH_CONTROLS_LOADED_EVENT = 'hotels-search-controls-loaded';

  constructor({
    deviceInfo,
    elementEventTracker,
    observerClient,
    pageName,
    region,
    statsD,
    trafficSource,
  }: Props) {
    this.statsd = new StatsDClient(statsD);
    this.funnelEvents = new FunnelEventsClient();
    this.pageName = pageName;
    this.region = region;
    this.ga = new GaClient(observerClient);
    this.deviceInfo = deviceInfo;
    this.trafficSource = trafficSource;
    this.elementEventTracker = elementEventTracker;
    this.firedEvents = {};
  }

  // Indicates that the user has done a new search by changing the destination,
  // dates, adults or rooms. Filtering or sorting search results is not included.
  userSearched({
    adm1,
    adm2,
    city,
    entityId,
    lat,
    lon,
    name,
    nation,
    requestId,
    skyscannerNodeCode,
    stay,
  }: UserSearched) {
    this.funnelEvents.trackSearch({
      entityId,
      skyscannerNodeCode,
      stay,
      lat,
      lon,
      name,
      nation,
      city,
      adm1,
      adm2,
      requestId,
    });
  }

  pageLoadStarted() {
    this.statsd.increment('pageLoad.start', 1, {
      pageName: this.pageName,
      region: this.region,
      isRobot: this.deviceInfo.isRobot,
      trafficSource: this.trafficSource,
    });
  }

  pageLoadSuccess({
    fallbackTranslations,
    isFromFlightDBookEmail,
    isLoggedIn,
    market,
  }: {
    market: string;
    isFromFlightDBookEmail: boolean;
    isLoggedIn: boolean;
    fallbackTranslations: boolean;
  }) {
    const eventFields = {
      market,
      fallbackTranslations,
      pageName: this.pageName,
      region: this.region,
      isRobot: this.deviceInfo.isRobot,
      trafficSource: this.trafficSource,
    };
    this.statsd.increment('pageLoad.success', 1, eventFields);
    logger.event('pageLoad.success', {
      ...eventFields,
      isRobot: String(this.deviceInfo.isRobot),
      fallbackTranslations: String(fallbackTranslations),
    });

    this.elementEventTracker.trackHotelsAction(
      this.pageName
        ? // @ts-ignore
          ACTION_TYPE[`${this.pageName}_page_load`.toUpperCase()]
        : null,
      buildAdditionalInfoPageLoadMessage({
        pageName: this.pageName,
        region: this.region,
        isLoggedIn,
      }),
    );

    this.ga.track(
      this.pageName,
      'pageLoadSuccess',
      JSON.stringify({
        isFromFlightDBookEmail,
      }),
    );
  }

  pageLoadFailure() {
    this.statsd.increment('pageLoad.failure', 1, {
      pageName: this.pageName,
      region: this.region,
      isRobot: this.deviceInfo.isRobot,
      trafficSource: this.trafficSource,
    });
  }

  searchLoaded() {
    if (!this.isEventFired(this.HOTELS_SEARCH_CONTROLS_LOADED_EVENT)) {
      logger.event(this.HOTELS_SEARCH_CONTROLS_LOADED_EVENT);
      this.statsd.increment('search.loaded', 1, { pageName: this.pageName });
      this.setEventFired(this.HOTELS_SEARCH_CONTROLS_LOADED_EVENT);
    }
  }

  // Indicates that the Search Button inside Search Controls has been submitted
  searchSubmitted() {
    if (!this.isEventFired(this.HOTELS_SEARCH_CONTROLS_CLICKED_EVENT)) {
      logger.event(this.HOTELS_SEARCH_CONTROLS_CLICKED_EVENT);
      this.statsd.increment('search.submitted', 1, { pageName: this.pageName });
      this.setEventFired(this.HOTELS_SEARCH_CONTROLS_CLICKED_EVENT);
    }
  }

  // Indicates that the Search Button inside Search Controls has been clicked
  searchClicked() {
    this.statsd.increment('search.clicked', 1, { pageName: this.pageName });
  }

  searchValidationFailed() {
    this.statsd.increment('search.validationFailed', 1, {
      pageName: this.pageName,
    });
  }

  private isEventFired(eventName: string): boolean {
    return this.firedEvents[eventName];
  }

  private setEventFired(eventName: string) {
    this.firedEvents[eventName] = true;
  }

  // Indicates that a new request for search results has been made. This can
  // happen because a user has done a new search or because filter or sort changed.
  searchCycleStarted({
    entityId,
    isNewSearch,
    name,
    searchStartFinishCycleId,
  }: {
    entityId: number;
    name: string;
    searchStartFinishCycleId: string;
    isNewSearch: boolean;
  }) {
    this.statsd.increment('search.start', 1, {
      isRobot: this.deviceInfo.isRobot,
      trafficSource: this.trafficSource,
      device: getDeviceType(this.deviceInfo),
    });

    this.elementEventTracker.trackHotelsAction(
      ACTION_TYPE.SEARCH_STARTED,
      buildAdditionalInfoSearchStartedMessage({
        pageName: this.pageName,
        entityID: entityId,
        entityName: name,
        searchStartFinishCycleId,
        isNewSearch,
      }),
    );
  }

  hotelsHunterFailure() {
    this.statsd.increment('hotelsHunter.failure');
  }

  hotelsHunterEmpty() {
    this.statsd.increment('hotelsHunter.empty');
  }

  hotelsHunterSuccess(timeTaken: number, tags = {}) {
    this.statsd.increment('hotelsHunter.success');
    this.statsd.timing('hotelsHunter.success', timeTaken, tags);
  }

  hotelsHunterCycleStarted() {
    this.statsd.increment('hotelsHunter.start');
  }

  searchSuccess(
    timeTaken: number,
    {
      campaignId,
      checkIn,
      checkOut,
      couponAmounts,
      couponHotels,
      couponRanks,
      couponType,
      cugAmounts,
      cugDeal,
      cugHotels,
      cugRanks,
      discountIncentive,
      discountPercentage,
      entityId,
      hasCoupon,
      requestId,
      searchId,
      searchStartFinishCycleId,
    }: {
      entityId: number;
      checkIn: string;
      checkOut: string;
      cugDeal: boolean;
      hasCoupon: boolean;
      couponType: string;
      discountPercentage: number;
      discountIncentive: number;
      couponHotels: number[];
      couponRanks: number[];
      couponAmounts: number[];
      cugHotels: number[];
      cugRanks: number[];
      cugAmounts: number[];
      campaignId: string;
      requestId: string;
      searchId: string;
      searchStartFinishCycleId: string;
    },
    tags = {},
  ) {
    this.elementEventTracker.trackHotelsAction(
      ACTION_TYPE.SEARCH_FINISHED,
      buildAdditionalInfoSearchFinishedMessage({
        pageName: this.pageName,
        entityID: entityId,
        checkIn,
        checkOut,
        cugDeal,
        hasCoupon,
        hasUpSortHotels: !!getCurrentQueryParam('upsort_hotels'),
        couponType,
        discountPercentage,
        discountIncentive,
        couponHotels,
        couponRanks,
        couponAmounts,
        cugAmounts,
        cugHotels,
        cugRanks,
        campaignId,
        requestId,
        searchId,
        searchStartFinishCycleId,
      }),
    );

    this.statsd.increment('search.success');
    this.statsd.timing('search.success', timeTaken, tags);
  }

  fastSearchSuccess(timeTaken: number, tags = {}) {
    this.statsd.increment('search.fast-success');
    this.statsd.timing('search.fast-success', timeTaken, tags);
  }

  searchEmpty() {
    this.statsd.increment('search.empty', 1, {
      isRobot: this.deviceInfo.isRobot,
      trafficSource: this.trafficSource,
      device: getDeviceType(this.deviceInfo),
    });
  }

  searchFailure() {
    this.statsd.increment('search.failure', 1, {
      isRobot: this.deviceInfo.isRobot,
      trafficSource: this.trafficSource,
      device: getDeviceType(this.deviceInfo),
    });
  }

  topSearchResultsLoaded({
    currency,
    hotels,
    searchStartFinishCycleId,
  }: {
    searchStartFinishCycleId: string;
    hotels: HotelCardShape[];
    currency: string;
  }) {
    hotels.forEach((hotel: HotelCardShape) => {
      this.elementEventTracker.trackHotelsAction(
        ACTION_TYPE.SEARCH_RESULT_LOADED,
        buildAdditionalInfoSearchResultLoadedMessage({
          searchStartFinishCycleId,
          hotel,
          currency,
        }),
      );
    });
  }

  autoSuggestSearch({
    duration,
    locale,
    status,
  }: {
    locale: string;
    status: number;
    duration: number;
  }) {
    this.statsd.increment('autoSuggest.search', 1, {
      pageName: this.pageName,
      locale,
      status,
    });
    logger.event(COMPONENT_ACTION.HOTEL_AUTOSUGGEST.AUTOSUGGEST_SEARCH, {
      duration,
      status,
    });
  }

  searchResultSelected({
    cugDeal,
    currency,
    hasCoupon,
    hotelId,
    isGoToHD,
    isMainPrice,
    isMapPanelHotel = false,
    isRecommendHotel = false,
    mainPriceArea,
    partnerId,
    position,
    price,
    searchId,
  }: {
    hotelId: number;
    position: number;
    price: number;
    partnerId: string;
    cugDeal: boolean;
    hasCoupon: boolean;
    searchId: string;
    currency: string;
    isMainPrice: boolean;
    isGoToHD: boolean;
    mainPriceArea: string;
    isRecommendHotel?: boolean;
    isMapPanelHotel?: boolean;
  }) {
    const isUpSortHotels =
      getCurrentQueryParam('upsort_hotels')?.split(',')?.includes(hotelId) ??
      false;
    this.statsd.increment('searchResultSelected', 1, {
      pageName: this.pageName,
    });

    this.elementEventTracker.trackHotelsAction(
      ACTION_TYPE.SEARCH_RESULT_SELECTED,
      buildAdditionalInfoSearchResultSelectedMessage({
        isCugDeal: cugDeal,
        hasCoupon,
        isUpSortHotels,
        hotelId,
        isGoToHD,
        isMainPrice,
        isRecommendHotel,
        isMapPanelHotel,
        mainPriceArea,
        partnerId,
        position,
        price: price && { currency, amount: Math.round(price), unit: 'WHOLE' },
        searchId,
        pageName: this.pageName,
      }),
    );
  }

  pricesStarted() {
    this.statsd.increment('prices.start', 1, {
      isRobot: this.deviceInfo.isRobot,
      trafficSource: this.trafficSource,
      device: getDeviceType(this.deviceInfo),
    });
  }

  pricesSuccess(
    timeTaken: number,
    {
      cugDeal,
      currency,
      dBook,
      forMobileControl,
      hasCoupon,
      hasDbook,
      hasImage,
      hasMeta,
      hotelId,
      isFiltered,
      isMapped,
      layoutType,
      partnerId,
      price,
      requestId,
      roomTypes,
      searchId,
      topFiveRateInfos,
      uniqueImageNum,
      uniquePartnerNum,
      uniqueRoomNameNum,
    }: {
      cugDeal: boolean;
      hasCoupon: boolean;
      hasDbook: boolean;
      hasMeta?: boolean;
      dBook: boolean;
      price: number;
      hotelId: string;
      searchId: string;
      partnerId: string;
      requestId: string;
      roomTypes?: string;
      layoutType?: string;
      isFiltered?: boolean;
      isMapped: boolean;
      uniquePartnerNum?: number;
      hasImage?: boolean;
      uniqueImageNum?: number;
      uniqueRoomNameNum?: number;
      topFiveRateInfos?: TopRateInfo[];
      forMobileControl?: boolean;
      currency: string;
    },
  ) {
    this.statsd.increment('prices.success');
    this.statsd.timing('prices.success', timeTaken);
    this.priceStatus({ hasRate: true, hotelId });

    if (forMobileControl)
      this.priceLoaded({
        cugDeal,
        dBook,
        hasCoupon,
        hasDbook,
        hasImage,
        hasMeta,
        hotelId,
        isFiltered,
        isMapped,
        layoutType,
        partnerId,
        price,
        requestId,
        roomTypes,
        searchId,
        currency,
        topFiveRateInfos,
        uniqueImageNum,
        uniquePartnerNum,
        uniqueRoomNameNum,
      });
  }

  priceLoaded({
    cugDeal,
    currency,
    dBook,
    hasCoupon,
    hasDbook,
    hasImage,
    hasMeta,
    hotelId,
    isFiltered,
    isMapped,
    layoutType,
    partnerId,
    price,
    requestId,
    roomTypes,
    searchId,
    topFiveRateInfos,
    uniqueImageNum,
    uniquePartnerNum,
    uniqueRoomNameNum,
  }: {
    cugDeal: boolean;
    hasCoupon: boolean;
    hasDbook: boolean;
    hasMeta?: boolean;
    dBook: boolean;
    price: number;
    hotelId: string;
    currency: string;
    searchId: string;
    partnerId: string;
    requestId: string;
    roomTypes?: string;
    layoutType?: string;
    isFiltered?: boolean;
    isMapped: boolean;
    uniquePartnerNum?: number;
    hasImage?: boolean;
    uniqueImageNum?: number;
    uniqueRoomNameNum?: number;
    topFiveRateInfos?: TopRateInfo[];
  }) {
    // @ts-ignore
    const actionType = layoutType && PRICES_LOADED_TYPES[layoutType];
    if (actionType) {
      this.elementEventTracker.trackHotelsAction(
        actionType,
        buildAdditionalInfoPricesLoadedMessage({
          isCugDeal: cugDeal,
          hasCoupon,
          hasDbook,
          hasMeta,
          dBook,
          hotelId,
          partnerId,
          price,
          currency,
          searchId,
          layoutType,
          pageName: this.pageName,
          requestId,
          roomTypes,
          isFiltered,
          isMapped,
          uniquePartnerNum,
          hasImage,
          uniqueImageNum,
          uniqueRoomNameNum,
          topFiveRateInfos,
        }),
      );
    }
  }

  pricesEmpty(hotelId: string) {
    this.statsd.increment('prices.empty', 1, {
      isRobot: this.deviceInfo.isRobot,
      trafficSource: this.trafficSource,
      device: getDeviceType(this.deviceInfo),
    });

    this.priceStatus({ hasRate: false, hotelId });
  }

  pricesFailure(hotelId: string) {
    this.statsd.increment('prices.failure', 1, {
      isRobot: this.deviceInfo.isRobot,
      trafficSource: this.trafficSource,
      device: getDeviceType(this.deviceInfo),
    });

    this.priceStatus({ hasRate: false, hotelId });
  }

  priceStatus({ hasRate, hotelId }: { hasRate: boolean; hotelId: string }) {
    this.elementEventTracker.trackHotelsAction(
      ACTION_TYPE.HOTEL_PRICES_STATUS,
      buildAdditionalInfoHotelPriceStatusMessage({
        hasRate,
        hotelId,
      }),
    );
  }

  callLeiFengSuccess() {
    this.statsd.increment('leifeng.deeplinking-success');
  }

  callLeiFengFail() {
    this.statsd.increment('leifeng.deeplinking-failure');
  }

  QRCodeGenerateSuccess() {
    this.statsd.increment('QRCode.success');
  }

  QRCodeGenerateFail() {
    this.statsd.increment('QRCode.failure');
  }

  priceSelected({
    clickArea,
    currency,
    dBook,
    hasImage,
    isFiltered,
    isLowest,
    isMapped,
    partnerId,
    position,
    positiveAmenities,
    price,
    rateFilterValues,
    roomFilterValue,
    roomName,
  }: PriceSelectedProps) {
    this.elementEventTracker.trackHotelsAction(
      dBook
        ? ACTION_TYPE.PRICE_DBOOK_SELECTED
        : ACTION_TYPE.PRICE_META_SELECTED,
      dBook
        ? buildAdditionalInfoPriceClickedInfo({
            isLowest,
            isFiltered,
            isDbook: dBook,
            rateFilterValues,
            roomFilterValue,
            position,
            partnerId,
            hasImage,
            roomName,
            positiveAmenities,
            clickArea,
            currency,
            isMapped,
            price,
          })
        : buildAdditionalInfoDbookOrMetaButtonClicked({
            isDbook: dBook,
          }),
    );

    // Total price selected click events of meta and dbook
    this.elementEventTracker.trackHotelsAction(
      ACTION_TYPE.PRICE_SELECTED,
      // @ts-ignore
      buildAdditionalInfoPriceClickedInfo({
        isDbook: dBook,
        isLowest,
        isFiltered,
        rateFilterValues,
        roomFilterValue,
        position,
        partnerId,
        hasImage,
        roomName,
        positiveAmenities,
        currency,
        isMapped,
        price,
      }),
    );
  }

  dbookPriceSelected() {
    this.statsd.increment('dbookRateSelected');
  }

  hotelDetailsPriceLoaded() {
    this.statsd.increment('hotelDetailsPriceLoaded');
  }

  similarHotelsSuccess(tags = {}) {
    this.statsd.increment('similarHotels.success', 1, tags);
  }

  similarHotelsEmpty(tags = {}) {
    this.statsd.increment('similarHotels.empty', 1, tags);
  }

  similarHotelsFailure(tags = {}) {
    this.statsd.increment('similarHotels.failure', 1, tags);
  }

  bookingStartFailure() {
    this.statsd.increment('dbook.booking-start-failure', 1, {});
  }

  bookingFlowTransition({
    hotelId,
    messageState,
    priceChangedInfo,
    processState,
    state,
  }: {
    processState: string;
    messageState?: string;
    state: string;
    hotelId: string;
    priceChangedInfo?: PriceChangedInfo;
  }) {
    this.statsd.increment('dbook.booking-flow-transition', 1, { state });

    this.bookingProcessState({
      hotelId,
      messageState,
      processState,
      priceChangedInfo,
    });
  }

  stathamBookingState(state: string, partnerId: string) {
    this.statsd.increment('dbook.statham-booking-state', 1, {
      state,
      partnerId,
    });
  }

  stathamBookingTiming(
    processMilliseconds: number,
    state: string,
    partnerId: string,
  ) {
    this.statsd.timing('dbook.statham-booking-state', processMilliseconds, {
      state,
      partnerId,
    });
  }

  preReservationAvailabilityCheck(
    state: string,
    {
      cugType,
      hotelId,
      isLoggedIn,
      market,
      prevTrackingId,
      priceConsistencyCheck,
      rateId,
      roomId,
      searchCycleId,
      trackingId,
      type,
    }: {
      hotelId: string;
      isLoggedIn: boolean;
      market: string;
      roomId: string;
      rateId: string;
      searchCycleId: string;
      trackingId: string | undefined;
      prevTrackingId: string;
      priceConsistencyCheck: boolean;
      type: string | undefined;
      cugType?: Maybe<string>;
    },
  ) {
    this.statsd.increment('dbook.statham-availability-check', 1, {
      state,
      market,
      type,
      priceConsistencyCheck,
    });

    logger.event('pre_reservation_availability_check', {
      state,
      market,
      roomId,
      rateId,
      prevTrackingId,
      priceConsistencyCheck: String(priceConsistencyCheck),
      trackingId,
      cugType,
      isLoggedIn,
      searchCycleId,
    });

    if (!priceConsistencyCheck) {
      this.elementEventTracker.trackHotelsAction(
        ACTION_TYPE.PRE_CHECK_STATE,
        buildAdditionalInfoPreCheckState({
          cugType,
          hotelId,
          isLoggedIn,
          preCheckState: state,
          prevTrackingId,
          priceConsistencyCheck,
          rateId,
          roomId,
          searchCycleId,
          trackingId,
        }),
      );
    }
  }

  finalScreenView(state: string, { reason }: { reason: string }) {
    this.statsd.increment('dbook.final-screen-view', 1, {
      state,
      reason,
    });
  }

  detailsMainCTAClicked(
    {
      currency,
      dBook,
      hasImage,
      isFiltered,
      isLowest,
      partnerId,
      position,
      positiveAmenities,
      price,
      rateFilterValues,
      roomFilterValue,
      roomName,
    }: PriceSelectedProps,
    isRedirect = true,
  ) {
    this.priceSelected({
      dBook,
      isLowest,
      isFiltered,
      rateFilterValues,
      roomFilterValue,
      position,
      partnerId,
      hasImage,
      roomName,
      positiveAmenities,
      price,
      currency,
    });
    if (dBook && isRedirect) this.dbookPriceSelected();
  }

  detailsOtherMetaPriceClicked({
    clickArea,
    currency,
    dBook,
    hasImage,
    isFiltered,
    isLowest,
    isMapped,
    partnerId,
    position,
    positiveAmenities,
    price,
    rateFilterValues,
    roomFilterValue,
    roomName,
  }: PriceSelectedProps) {
    this.priceSelected({
      dBook,
      isLowest,
      isFiltered,
      rateFilterValues,
      roomFilterValue,
      position,
      partnerId,
      hasImage,
      roomName,
      positiveAmenities,
      clickArea,
      currency,
      isMapped,
      price,
    });
    if (dBook) this.dbookPriceSelected();
  }

  bookingPerformed({
    bookingId,
    bookingStatus,
    cug,
    currency,
    hotelId,
    partnerId,
    price,
    roomRateId,
    searchId,
  }: {
    bookingId: string;
    bookingStatus: string;
    currency: string;
    hotelId: string;
    partnerId: string;
    price?: number;
    roomRateId?: string;
    searchId: string;
    cug?: CugPriceShape;
  }) {
    this.elementEventTracker.trackHotelsAction(
      ACTION_TYPE.BOOKING_PERFORMED,
      buildAdditionInfoBookingPerformed({
        bookingId,
        // @ts-ignore
        bookingStatus: BOOKING_STATUS_MAPPING[bookingStatus],
        cug,
        currency,
        hotelId,
        partnerId,
        price,
        roomRateId,
        searchId,
      }),
    );
  }

  bookingProcessState({
    hotelId,
    messageState,
    policyChanedType,
    priceChangedInfo,
    processState,
  }: {
    processState: string;
    messageState?: string;
    hotelId: string;
    priceChangedInfo?: PriceChangedInfo;
    policyChanedType?: string;
  }) {
    this.elementEventTracker.trackHotelsAction(
      ACTION_TYPE.BOOKING_PROCESS_STATE,
      buildAdditionInfoBookingProcessState({
        hotelId,
        messageState,
        processState,
        priceChangedInfo,
        policyChanedType,
      }),
    );
  }

  paymentAuthChallengeIframeLoad({ partnerId }: PaymentProps) {
    this.statsd.increment('paymentAuthChallenge.load', 1, {
      pageName: this.pageName,
      partnerId,
    });
  }

  paymentAuthChallengeIframeInitialFrameLoad({ partnerId }: PaymentProps) {
    this.statsd.increment('paymentAuthChallenge.initialFrameLoad', 1, {
      pageName: this.pageName,
      partnerId,
    });
  }

  paymentAuthChallengeIframeSubsequentFrameLoad({ partnerId }: PaymentProps) {
    this.statsd.increment('paymentAuthChallenge.subsequentFrameLoad', 1, {
      pageName: this.pageName,
      partnerId,
    });
  }

  paymentAuthChallengeIframeCompleteChallenge({ partnerId }: PaymentProps) {
    this.statsd.increment('paymentAuthChallenge.completeChallenge', 1, {
      pageName: this.pageName,
      partnerId,
    });
  }

  bookingSubmitError({ errorsFields }: OneOfTag) {
    this.statsd.increment('booking-submit-error', 1, {
      region: this.region,
      errors_fields: errorsFields,
    });
  }

  searchMapEntryClicked() {
    this.statsd.increment('searchMap-entry-clicked');
  }

  searchMapMarkerClicked() {
    this.statsd.increment('searchMap-marker-clicked');
  }

  searchMapCardSwiped() {
    this.statsd.increment('searchMap-card-swiped');
  }

  savePhoneNumberSeen() {
    this.statsd.increment('savePhoneNumber-savePhoneNumberSeen', 1, {
      region: this.region,
      device: getDeviceType(this.deviceInfo),
    });
  }

  savePhoneNumberChecked() {
    this.statsd.increment('savePhoneNumber-saveChecked', 1, {
      region: this.region,
      device: getDeviceType(this.deviceInfo),
    });
  }

  savePhoneNumberFailed() {
    this.statsd.increment('savePhoneNumber-savePhoneNumberFailed', 1, {
      region: this.region,
      device: getDeviceType(this.deviceInfo),
    });
  }

  savePhoneNumberStatus({
    code,
    message,
  }: {
    code?: string;
    message?: string;
  }) {
    this.statsd.increment('savePhoneNumber-savePhoneNumberStatus', 1, {
      region: this.region,
      device: getDeviceType(this.deviceInfo),
      code,
      message,
    });
  }

  saveThisCardSeen() {
    this.statsd.increment('saveRetrieveCard-saveThisCardSeen', 1, {
      region: this.region,
      device: getDeviceType(this.deviceInfo),
    });
  }

  saveCardChecked() {
    this.statsd.increment('saveRetrieveCard-cardChecked', 1, {
      region: this.region,
      device: getDeviceType(this.deviceInfo),
    });
  }

  storedCardSelected({ token }: { token: string }) {
    this.statsd.increment('saveRetrieveCard-storedCardSelected', 1, {
      card: token,
      region: this.region,
      device: getDeviceType(this.deviceInfo),
    });
  }

  cardManageEdit() {
    this.statsd.increment('saveRetrieveCard-cardEditClicked', 1, {
      region: this.region,
      device: getDeviceType(this.deviceInfo),
    });
  }

  cardUpdateSave() {
    this.statsd.increment('saveRetrieveCard-cardUpdateClicked', 1, {
      region: this.region,
      device: getDeviceType(this.deviceInfo),
    });
  }

  createCardStatus({ statusCode }: CreateCardProps) {
    this.statsd.increment('saveRetrieveCard-create', 1, {
      status: statusCode,
      region: this.region,
      device: getDeviceType(this.deviceInfo),
    });
  }

  retrieveCardStatus({ statusCode }: CreateCardProps) {
    this.statsd.increment('saveRetrieveCard-retrieve', 1, {
      status: statusCode,
      region: this.region,
      device: getDeviceType(this.deviceInfo),
    });
  }

  updateCardStatus({ statusCode }: CreateCardProps) {
    this.statsd.increment('saveRetrieveCard-update', 1, {
      status: statusCode,
      region: this.region,
      device: getDeviceType(this.deviceInfo),
    });
  }

  threeDSDeviceIframeComplete({ partnerId }: PaymentProps) {
    this.statsd.increment('threeDSDevice.complete', 1, {
      pageName: this.pageName,
      partnerId,
    });
  }

  threeDSDeviceIframeLoad({ partnerId }: PaymentProps) {
    this.statsd.increment('threeDSDevice.load', 1, {
      pageName: this.pageName,
      partnerId,
    });
  }

  threeDSDeviceIframeInitialFrameLoad({ partnerId }: PaymentProps) {
    this.statsd.increment('threeDSDevice.initialFrameLoad', 1, {
      pageName: this.pageName,
      partnerId,
    });
  }

  threeDSDeviceIframeSubsequentFrameLoad({ partnerId }: PaymentProps) {
    this.statsd.increment('threeDSDevice.subsequentFrameLoad', 1, {
      pageName: this.pageName,
      partnerId,
    });
  }

  threeDSStart({ partnerId, type }: PaymentProps) {
    this.statsd.increment('threeDS.start', 1, {
      partnerId,
      type,
    });
  }

  threeDSSuccess({ partnerId, type }: PaymentProps) {
    this.statsd.increment('threeDS.success', 1, {
      partnerId,
      type,
    });
  }

  threeDS2OfSCStart({ currency, market, partnerId }: ThreeDS2PaymentProps) {
    this.statsd.increment('threeDSTwoOfSC.start', 1, {
      region: this.region,
      device: getDeviceType(this.deviceInfo),
      partnerId,
      market,
      currency,
    });
  }

  threeDS2OfSCSuccess({ currency, market, partnerId }: ThreeDS2PaymentProps) {
    this.statsd.increment('threeDSTwoOfSC.success', 1, {
      region: this.region,
      device: getDeviceType(this.deviceInfo),
      partnerId,
      market,
      currency,
    });
  }

  threeDS2OfSCFailed({ currency, market, partnerId }: ThreeDS2PaymentProps) {
    this.statsd.increment('threeDSTwoOfSC.failed', 1, {
      region: this.region,
      device: getDeviceType(this.deviceInfo),
      partnerId,
      market,
      currency,
    });
  }

  babysharkSuccess({
    currency,
    env,
    isSupported,
    issuercc,
    market,
    partnerId,
  }: BabysharkPrecheckProps) {
    this.statsd.increment('babyshark.preauthenticate-success', 1, {
      region: this.region,
      device: getDeviceType(this.deviceInfo),
      partnerId,
      market,
      currency,
      env,
      issuercc,
      isSupported,
    });
  }

  babysharkFailed({
    currency,
    env,
    market,
    partnerId,
  }: BabysharkPrecheckProps) {
    this.statsd.increment('babyshark.preauthenticate-failed', 1, {
      region: this.region,
      device: getDeviceType(this.deviceInfo),
      partnerId,
      market,
      currency,
      env,
    });
  }

  unifiedPaymentStatus({
    action,
    bookingId,
    market,
    partnerId,
    status,
  }: {
    action: string;
    market: string;
    partnerId: string;
    status: string;
    bookingId: string;
  }) {
    this.statsd.increment('nairobi.unified.payment', 1, {
      device: getDeviceType(this.deviceInfo),
      action,
      status,
      market,
      partnerId,
    });

    logger.event('nairobi.unified.payment', {
      action,
      bookingId,
      status,
      market,
      partnerId,
    });

    this.elementEventTracker.trackHotelsAction(
      ACTION_TYPE.UNIFIED_PAYMENT_INTEGRATION,
      buildAdditionalInfoUnifiedPaymentPlatformMessage({
        action,
        bookingId,
        partnerId,
        status: status === '200' ? 'SUCCESSFUL' : 'ERROR',
      }),
    );
  }

  componentLoadFailure({
    componentName,
    market,
  }: {
    market: string;
    componentName: string;
  }) {
    this.statsd.increment('componentLoad.failure', 1, {
      pageName: this.pageName,
      market,
      componentName,
      isRobot: this.deviceInfo.isRobot,
      device: getDeviceType(this.deviceInfo),
    });
  }

  searchFilterUsed({
    filterArea,
    filterId,
    filterOptions,
    filterValue,
    originalSearchStartFinishCycleId,
    searchStartFinishCycleId,
  }: {
    filterId: string;
    filterValue: string[];
    filterOptions: Array<{
      id: string;
      count: number;
      enabled: boolean;
      label: string;
    }>;
    originalSearchStartFinishCycleId: string;
    searchStartFinishCycleId: string;
    filterArea?: string;
  }) {
    this.elementEventTracker.trackHotelsAction(
      ACTION_TYPE.DAY_VIEW_FILTERS_USED,
      buildDayViewFiltersUsedMessage({
        filterId,
        filterValue,
        filterOptions,
        originalSearchStartFinishCycleId,
        searchStartFinishCycleId,
        filterArea,
      }),
    );
  }

  searchSortUsed({
    entityId,
    originalSearchStartFinishCycleId,
    searchStartFinishCycleId,
    selectedValue,
    sortClickArea = null,
  }: {
    selectedValue: string;
    entityId: number;
    searchStartFinishCycleId: string;
    originalSearchStartFinishCycleId: string;
    sortClickArea?: string | null;
  }) {
    this.elementEventTracker.trackHotelsAction(
      ACTION_TYPE.DAY_VIEW_SORT_USED,
      buildDayViewSortUsedMessage({
        selectedValue,
        entityId,
        searchStartFinishCycleId,
        originalSearchStartFinishCycleId,
        sortClickArea,
      }),
    );
  }

  searchPaginationClick({
    originalSearchStartFinishCycleId,
    pageIndex,
    searchStartFinishCycleId,
  }: {
    pageIndex: number;
    searchStartFinishCycleId: string;
    originalSearchStartFinishCycleId: string;
  }) {
    this.elementEventTracker.trackHotelsAction(
      ACTION_TYPE.DAY_VIEW_PAGINATION_CLICK,
      {
        index: {
          index: pageIndex,
          search_start_finish_cycle_id: searchStartFinishCycleId,
          original_search_start_finish_cycle_id:
            originalSearchStartFinishCycleId,
        },
      },
    );
  }

  errorBoundaryButtonClick() {
    this.statsd.increment('errorBoundary.goBack.clicked', 1, {
      pageName: this.pageName,
    });
  }
}

export default ApplicationMetrics;

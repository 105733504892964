import { Component } from 'react';

import { isSameDay } from 'date-fns/isSameDay';

import {
  ALERT_TYPES,
  BpkBannerAlertExpandable,
  withBannerAlertState,
} from '@skyscanner/backpack-web/bpk-component-banner-alert';
import BpkInput, {
  INPUT_TYPES,
} from '@skyscanner/backpack-web/bpk-component-input';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text/src/BpkText';
import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import { stayLength } from '../../../date-utils';
import { DATE_FORMAT, withI18n } from '../../../services/i18n';
import SplitRenderingHere from '../../Common/SplitRenderingHere';
import { withUserContextProvider } from '../../UserContextProvider';
import { withPriceData } from '../PriceDataProvider';

import ApplyFooter from './ApplyFooter';
import ScrollableRangeCalendar from './ScrollRangeCalendar/ScrollableRangeCalendar';
import calculatePrice from './calculatePrice';

import type { I18nShape } from '../../../services/i18n';
import type { CalendarPriceDataShape, Maybe } from '../../../types';
import type { UserContextShape } from 'common-types/types/hotels-components/types';

import STYLES from './ScrollableDateRangeSelector.scss';

type Props = {
  i18n: I18nShape;
  onApply: Function;
  onClose: Function;
  startDate: Date;
  endDate: Date;
  isWithBannerAlert?: boolean;
  priceData: Maybe<CalendarPriceDataShape>;
  userContext: UserContextShape; // TODO: CASTLE-2329 remove userContext
};

type State = {
  startDate: Date;
  endDate: Date;
};

const defaultProps = {
  isWithBannerAlert: false,
};

const cls = cssModules(STYLES);

const BannerAlertExpandableState = withBannerAlertState(
  BpkBannerAlertExpandable,
);

class ScrollableDateRangeSelector extends Component<Props, State> {
  static defaultProps = defaultProps;

  constructor(props: Props) {
    super(props);
    const { endDate, startDate } = props;
    this.state = {
      startDate,
      endDate,
    };
  }

  onSubmit = () => {
    const { onApply, onClose } = this.props;
    const { endDate, startDate } = this.state;

    onClose();
    onApply(startDate, endDate);
  };

  onDateRangeChange = (startDate: Date, endDate: Date) => {
    this.setState({ startDate, endDate });
  };

  render() {
    const { endDate, startDate } = this.state;
    const {
      i18n: { formatDate: formatDateI18n, translate },
      isWithBannerAlert,
      priceData,
    } = this.props;
    const nightCount = stayLength({
      checkIn: startDate,
      // @ts-ignore
      checkOut: endDate,
    });

    const { averagePrice, minPrice } = calculatePrice(
      startDate,
      endDate,
      priceData,
      nightCount,
      formatDateI18n,
    );
    const showCalculatedPrice = averagePrice !== 0;

    return (
      <div className={cls('ScrollableDateRangeSelector')}>
        {isWithBannerAlert && (
          <div className={cls('ScrollableDateRangeSelector__header')}>
            <div className={cls('ScrollableDateRangeSelector__alert')}>
              <BpkText tagName="p" textStyle={TEXT_STYLES.bodyDefault}>
                <BannerAlertExpandableState
                  message={translate('DateRangeSelector_banner_title')}
                  type={ALERT_TYPES.NEUTRAL}
                  toggleButtonLabel={translate('Button_viewMore')}
                >
                  {translate('DateRangeSelector_banner_content')}
                </BannerAlertExpandableState>
              </BpkText>
            </div>
          </div>
        )}

        <div className={cls('ScrollableDateRangeSelector__content')}>
          <div className={cls('ScrollableDateRangeSelector__dateRange')}>
            <div
              className={cls(
                'ScrollableDateRangeSelector__date',
                'ScrollableDateRangeSelector__date--checkIn',
              )}
            >
              <BpkText tagName="p" textStyle={TEXT_STYLES.label2}>
                {translate('SelectionSummary_label_checkIn')}
              </BpkText>
              <BpkInput
                id="scrollableDateCheckInId"
                type={INPUT_TYPES.text}
                name="scrollableDateCheckInId"
                className={cls('ScrollableDateRangeSelector__date--input')}
                value={
                  startDate
                    ? formatDateI18n(
                        startDate,
                        DATE_FORMAT.MONTH_DAY_ABBR,
                      ).replace(',', '')
                    : translate('HotelStay_label_checkIn')
                }
                placeholder={translate('HotelStay_label_checkIn')}
                readOnly
              />
            </div>
            <div
              className={cls(
                'ScrollableDateRangeSelector__date',
                'ScrollableDateRangeSelector__date--checkOut',
              )}
            >
              <BpkText tagName="p" textStyle={TEXT_STYLES.label2}>
                {translate('SelectionSummary_label_checkOut')}
              </BpkText>
              <BpkInput
                id="scrollableDateCheckOutId"
                type={INPUT_TYPES.text}
                name="scrollableDateCheckOutId"
                className={cls('ScrollableDateRangeSelector__date--input')}
                value={
                  endDate
                    ? formatDateI18n(
                        endDate,
                        DATE_FORMAT.MONTH_DAY_ABBR,
                      ).replace(',', '')
                    : translate('HotelStay_label_checkOut')
                }
                placeholder={translate('HotelStay_label_checkOut')}
                readOnly
                disabled={!endDate}
              />
            </div>
          </div>
          <SplitRenderingHere
            enabled={
              this.props.userContext.enableDateRangeSelectorSplitRendering
            }
          >
            <ScrollableRangeCalendar
              startDate={startDate}
              endDate={endDate}
              onDateRangeChange={this.onDateRangeChange}
            />
          </SplitRenderingHere>
          <ApplyFooter
            checkOut={endDate}
            disabled={
              !startDate ||
              !endDate ||
              isSameDay(startDate, endDate) ||
              (startDate === this.props.startDate &&
                endDate === this.props.endDate)
            }
            showCalculatedPrice={showCalculatedPrice}
            averagePrice={averagePrice}
            nightCount={nightCount}
            minPrice={minPrice}
            onApply={this.onSubmit}
          />
        </div>
      </div>
    );
  }
}

// TODO: CASTLE-2329 remove userContext
export default withUserContextProvider(
  withI18n(withPriceData(ScrollableDateRangeSelector)),
);

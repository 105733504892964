/* eslint-disable no-console */

import observer from 'saddlebag-observer';

import type { ObserverClientReturn } from '../types';

type PropsClient = {
  destinations: Array<'observer' | 'console' | 'event'>;
};

type PropsBuilder = {
  environment: 'development' | 'production' | 'tests';
};

export const ObserverClient = ({
  destinations,
}: PropsClient): ObserverClientReturn => ({
  track: (event, data) => {
    if (destinations.find((dest) => dest === 'observer')) {
      observer.publish(event, data);
    }

    if (destinations.find((dest) => dest === 'console')) {
      console.info(event, data);
    }

    if (destinations.find((dest) => dest === 'event')) {
      try {
        const tracker = new Event(event);
        Object.keys(data).forEach((key) => {
          // @ts-ignore
          tracker[key] = data[key];
        });
        window.dispatchEvent(tracker);
      } catch (e) {
        // this feature is only used for the acceptance tests and running on the headless Chrome
        // to check weather the mixpanel/mini events are fired, but IE11 doesn't support it
      }
    }
  },
});

const buildObserverClient = ({
  environment,
}: PropsBuilder): ObserverClientReturn => {
  if (environment === 'development') {
    return ObserverClient({
      destinations: ['console'],
    });
  }

  if (environment === 'production') {
    return ObserverClient({ destinations: ['observer'] });
  }

  return ObserverClient({ destinations: ['observer', 'console', 'event'] });
};

export default buildObserverClient;

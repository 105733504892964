import { parseISO } from 'date-fns/parseISO';

import {
  ACTION_TYPE,
  PRICE_TYPE,
  SORTING_COLUMNS,
  FILTER_AREA_TYPE,
  REGION,
  FILTER_VALUE_TYPE,
  FILTER_TYPE,
  FILTER_OPTION_TYPE,
  RATES_FILTER_TYPE,
  AUTO_SUGGEST_POI_LIST,
  BASE_ROOM_TYPE,
  SORTING_CLICK_AREA_TYPE,
  RATE_LAYOUT_TYPE,
  CUG_TYPE,
  MESSAGE_STATE,
  BOOKING_PROCESS_STATE,
  BOOKING_STATUS,
  HOTEL_CARD_AREA,
  PRICE_CHANGED_TYPE,
  POLICY_CHANGED_TYPE,
  UNIFIED_INTEGRATION_STEP_TYPE,
  ACTION_STATUS_YPE,
  PARTNER_ID,
} from './constants';

export const PRICES_LOADED_TYPES = {
  ONLY_META: ACTION_TYPE.PRICES_LOADED_META_ONLY,
  META_AND_DBOOK: ACTION_TYPE.PRICES_LOADED_HYBRID,
  ONLY_DBOOK: ACTION_TYPE.PRICES_LOADED_DBOOK_ONLY,
};

const getUnixTimeStamp = (timestamp) => ({
  unix_time_millis: Math.round(parseISO(timestamp).getTime() / 1000),
  timezone_offset_mins: 0,
  daylight_savings_offset_mins: 0,
  date_time_kind: 'MILLI',
});

const getFilterValue = (filterId, filterValue) => ({
  [filterId]: Object.keys(FILTER_VALUE_TYPE[filterId]).reduce((acc, curr) => {
    acc[FILTER_VALUE_TYPE[filterId][curr]] = filterValue.includes(curr);
    return acc;
  }, {}),
});

const getFilterValueWithOption = (filterId, filterValue, filterOptions) => ({
  [FILTER_OPTION_TYPE[filterId]]: Object.keys(
    FILTER_VALUE_TYPE[filterId],
  ).reduce((acc, curr) => {
    const optionValue = {
      is_selected: filterValue.includes(curr),
      is_available: filterOptions.some(
        (opt) => opt.id === curr && opt.count > 0,
      ),
    };
    acc[FILTER_VALUE_TYPE[filterId][curr]] = optionValue;
    return acc;
  }, {}),
});

const getHotelCardArea = ({ isMapPanelHotel, isRecommendHotel }) => {
  if (isMapPanelHotel) {
    return HOTEL_CARD_AREA.MAP_PANEL;
  }

  if (isRecommendHotel) {
    return HOTEL_CARD_AREA.RECOMMENDED;
  }
  return HOTEL_CARD_AREA.UNSET_HOTEL_CARD_AREA;
};

export const HOTELS_FRONTEND_SCHEMA_NAME = 'hotels_frontend.HotelsAction';
export const HOTELS_FRONTEND_MINIEVENT_NAME = 'HotelsFrontendHotelsAction';

export const buildAdditionalInfoDayViewSearch = ({
  IsSearchWithChildren,
  entityID,
  entityName,
  entityType,
  filters,
}) => ({
  day_view_search: {
    entity_id: entityID,
    entity_name: entityName,
    is_search_with_children: IsSearchWithChildren,
    filters,
    entity_type: entityType,
  },
});

export const buildAdditionalInfoRoomInfoModalButtonClicked = ({
  clickArea,
  rateGroupIndex,
  roomGroupIndex,
}) => ({
  room_info_modal_button_clicked: {
    rate_group_index: rateGroupIndex,
    room_group_index: roomGroupIndex,
    click_area: clickArea,
  },
});

export const buildAdditionalInfoDbookOrMetaButtonClicked = ({ isDbook }) => ({
  dbook_or_meta_clicked: {
    is_dbook: isDbook,
  },
});

export const buildAdditionalInfoPriceClickedInfo = ({
  clickArea,
  currency,
  hasImage,
  isDbook,
  isFiltered,
  isLowest,
  isMapped,
  partnerId,
  position,
  positiveAmenities,
  price,
  rateFilterValues,
  roomFilterValue,
  roomName,
}) => ({
  price_clicked_info: {
    is_dbook: isDbook,
    is_lowest: isLowest,
    is_filtered: isFiltered,
    is_mapped: isMapped,
    rate_filter_values: rateFilterValues,
    room_filter_value: roomFilterValue,
    ranking: position,
    partner_id: partnerId,
    price: price && { currency, amount: Math.round(price), unit: 'WHOLE' },
    has_image: hasImage,
    room_name: roomName,
    positive_amenity_values: positiveAmenities,
    click_area: clickArea,
  },
});

export const getDayViewHotelCardClickedActionType = (
  funnelType,
  mainPriceArea,
) => {
  if (funnelType === 'dbook') {
    return ACTION_TYPE.DAY_VIEW_HOTEL_CARD_DBOOK_CLICKED;
  }

  if (mainPriceArea === 'lowestPriceBtn' || mainPriceArea === 'other') {
    return ACTION_TYPE.DAY_VIEW_META_REDIRECT;
  }
  return ACTION_TYPE.DAY_VIEW_HOTEL_CARD_META_CLICKED;
};

export const buildDayViewHotelCardClickedMessage = ({
  couponType,
  cug,
  goToDVEnabled,
  hasCoupon,
  isCoupon,
  isGoToHD,
  isMainPrice,
  isMapPanelHotel = false,
  mainPriceArea,
  partnerId,
  price_type: priceType,
  stars,
}) => {
  const cugType = (cug && cug.type && cug.type.toUpperCase()) || null;

  return {
    day_view_hotel_card_clicked: {
      is_go_to_dv: goToDVEnabled,
      has_coupon_in_search_result: hasCoupon,
      is_coupon_hotel: isCoupon,
      is_go_to_hd: isGoToHD,
      is_main_price: isMainPrice,
      main_price_area: mainPriceArea,
      partner_id: partnerId,
      price_type: priceType
        ? PRICE_TYPE[priceType.toUpperCase()]
        : PRICE_TYPE.UNSET_PRICE_TYPE,
      stars,
      coupon_type: couponType,
      hotel_card_area: getHotelCardArea({
        isRecommendHotel: false,
        isMapPanelHotel,
      }),
      has_cug_deal: !!cug,
      cug_type: CUG_TYPE[cugType] ? CUG_TYPE[cugType] : CUG_TYPE.UNSET_CUG_TYPE,
      cug_deal_percentage: cug && cug.discountPercentage,
    },
  };
};

export const buildDayViewFiltersUsedMessage = ({
  filterArea,
  filterId,
  filterOptions,
  filterValue,
  originalSearchStartFinishCycleId,
  searchStartFinishCycleId,
}) => {
  let filtersUsed = {};
  const filterType =
    (filterId && FILTER_TYPE[filterId.toUpperCase()]) ||
    FILTER_TYPE.UNSET_FILTER_TYPE;
  if (FILTER_VALUE_TYPE[filterId] && filterValue.length) {
    if (FILTER_OPTION_TYPE[filterId]) {
      filtersUsed = getFilterValueWithOption(
        filterId,
        filterValue,
        filterOptions,
      );
    } else {
      filtersUsed = getFilterValue(filterId, filterValue);
    }
  }
  return {
    day_view_filters_used: {
      ...filtersUsed,
      filter_type: filterType,
      filter_area_type: filterArea
        ? FILTER_AREA_TYPE[filterArea.toUpperCase()]
        : FILTER_AREA_TYPE.FILTER_PANEL,
      original_search_start_finish_cycle_id: originalSearchStartFinishCycleId,
      search_start_finish_cycle_id: searchStartFinishCycleId,
    },
  };
};

export const buildDayViewSortUsedMessage = ({
  entityId,
  originalSearchStartFinishCycleId,
  searchStartFinishCycleId,
  selectedValue,
  sortClickArea,
}) => ({
  day_view_sort_used: {
    entity_id: entityId,
    selected_sorting_columns: selectedValue
      ? SORTING_COLUMNS[selectedValue.toUpperCase()]
      : 'UNSET_SORTING_COLUMNS',
    original_search_start_finish_cycle_id: originalSearchStartFinishCycleId,
    search_start_finish_cycle_id: searchStartFinishCycleId,
    sorting_click_area: sortClickArea
      ? SORTING_CLICK_AREA_TYPE[sortClickArea]
      : SORTING_CLICK_AREA_TYPE.UNSET_SORTING_CLICK_AREA,
  },
});

export const buildAdditionalInfoPageLoadMessage = ({
  isLoggedIn,
  pageName,
  region,
}) => ({
  page_load: {
    page_name: pageName,
    region: region ? REGION[region.toUpperCase()] : null,
    is_logged_in: isLoggedIn,
  },
});

export const buildAdditionalInfoSearchResultSelectedMessage = ({
  hasCoupon,
  hotelId,
  isCugDeal,
  isGoToHD,
  isMainPrice,
  isMapPanelHotel,
  isRecommendHotel,
  isUpSortHotels,
  mainPriceArea,
  pageName,
  partnerId,
  position,
  price,
  searchId,
}) => ({
  search_result_selected: {
    has_cug_deal: isCugDeal,
    has_coupon: hasCoupon,
    is_up_sorted_hotels: isUpSortHotels,
    hotel_id: parseInt(hotelId, 10),
    is_go_to_hd: isGoToHD,
    is_main_price: isMainPrice,
    is_recommend_hotel: isRecommendHotel,
    hotel_card_area: getHotelCardArea({
      isRecommendHotel,
      isMapPanelHotel,
    }),
    main_price_area: mainPriceArea,
    partner_id: partnerId,
    position,
    price,
    search_id: searchId,
    page_name: pageName,
  },
});

export const buildAdditionalInfoPricesLoadedMessage = ({
  currency,
  dBook,
  hasCoupon,
  hasDbook,
  hasImage,
  hasMeta,
  hotelId,
  isCugDeal,
  isFiltered,
  isMapped,
  layoutType,
  pageName,
  partnerId,
  price,
  requestId,
  roomTypes,
  searchId,
  topFiveRateInfos,
  uniqueImageNum,
  uniquePartnerNum,
  uniqueRoomNameNum,
}) => {
  const refactoredTopFiveRateInfos = topFiveRateInfos
    ? topFiveRateInfos.map(
        ({
          baseRoomType,
          isMappedForTopFive,
          partnerId: ratePartnerId,
          price: ratePrice,
          rateId,
          roomId,
        }) => ({
          is_mapped: isMappedForTopFive,
          partner_id: ratePartnerId,
          price: { currency, amount: Math.round(ratePrice), unit: 'WHOLE' },
          room_id: roomId,
          rate_id: rateId,
          base_room_type:
            BASE_ROOM_TYPE[baseRoomType] || BASE_ROOM_TYPE.UNSET_ROOM_TYPE,
        }),
      )
    : [];
  return {
    prices_loaded: {
      has_cug_deal: isCugDeal,
      has_coupon: hasCoupon,
      has_dbook: hasDbook,
      has_meta: hasMeta,
      is_dbook: dBook,
      hotel_id: parseInt(hotelId, 10),
      partner_id: partnerId,
      price: { currency, amount: Math.round(price), unit: 'WHOLE' },
      search_id: searchId,
      page_name: pageName,
      request_id: requestId,
      room_types: roomTypes,
      is_filtered: isFiltered,
      is_mapped: isMapped,
      layout_type: RATE_LAYOUT_TYPE[layoutType],
      top_five_additional_information:
        hasImage === undefined
          ? undefined
          : {
              top5_unique_partner_number: uniquePartnerNum,
              has_image_in_top5: hasImage,
              top5_unique_image_number: uniqueImageNum,
              top5_unique_room_number: uniqueRoomNameNum,
            },
      top_rate_one: refactoredTopFiveRateInfos[0],
      top_rate_two: refactoredTopFiveRateInfos[1],
      top_rate_three: refactoredTopFiveRateInfos[2],
      top_rate_four: refactoredTopFiveRateInfos[3],
      top_rate_five: refactoredTopFiveRateInfos[4],
    },
  };
};

export const buildAdditionalInfoSearchStartedMessage = ({
  entityID,
  entityName,
  isNewSearch,
  pageName,
  searchStartFinishCycleId,
}) => ({
  search_started: {
    page_name: pageName,
    entity_id: parseInt(entityID, 10),
    entity_name: entityName,
    search_start_finish_cycle_id: searchStartFinishCycleId,
    is_new_search: isNewSearch,
  },
});

const isLowestPrice = ({ allPricesInfo, priceInfo: { price } }) => {
  if (
    !allPricesInfo ||
    !allPricesInfo.prices ||
    !allPricesInfo.prices.length ||
    allPricesInfo.prices.length === 1
  ) {
    return true;
  }

  return allPricesInfo.prices.slice(1).every((rate) => rate.price > price);
};

export const buildAdditionalInfoSearchResultLoadedMessage = ({
  currency,
  hotel,
  searchStartFinishCycleId,
}) => {
  const { cug } = hotel.priceInfo;
  const cugType = (cug && cug.type && cug.type.toUpperCase()) || null;

  return {
    search_result_loaded: {
      search_start_finish_cycle_id: searchStartFinishCycleId,
      hotel_id: parseInt(hotel.id, 10),
      hotel_stars: hotel.numberOfStars || 0,
      price: hotel.priceInfo.price && {
        currency,
        amount: Math.round(hotel.priceInfo.price),
        unit: 'WHOLE',
      },
      partner_id: hotel.priceInfo.partnerId,
      has_cug_deal: !!cug,
      cug_deal_percentage: cug && cug.discountPercentage,
      cug_type: CUG_TYPE[cugType] ? CUG_TYPE[cugType] : CUG_TYPE.UNSET_CUG_TYPE,
      is_lowest: isLowestPrice(hotel),
    },
  };
};

export const buildAdditionalInfoSearchFinishedMessage = ({
  campaignId,
  checkIn,
  checkOut,
  couponAmounts,
  couponHotels,
  couponRanks,
  couponType,
  cugAmounts,
  cugDeal,
  cugHotels,
  cugRanks,
  discountIncentive,
  discountPercentage,
  entityID,
  hasCoupon,
  hasUpSortHotels,
  pageName,
  requestId,
  searchId,
  searchStartFinishCycleId,
}) => ({
  search_finished: {
    page_name: pageName,
    entity_id: parseInt(entityID, 10),
    check_in_timestamp: getUnixTimeStamp(checkIn),
    check_out_timestamp: getUnixTimeStamp(checkOut),
    has_cug_deal: cugDeal,
    has_coupon: hasCoupon,
    has_up_sorted_hotels: hasUpSortHotels,
    coupon_type: couponType,
    discount_percentage: discountPercentage,
    discount_incentive: discountIncentive,
    coupon_hotels: couponHotels,
    coupon_ranks: couponRanks,
    coupon_amounts: couponAmounts,
    cug_hotels: cugHotels?.join(', ') ?? null,
    cug_ranks: cugRanks?.join(', ') ?? null,
    cug_amounts: cugAmounts?.join(', ') ?? null,
    campaign_id: campaignId,
    request_id: requestId,
    search_id: searchId,
    search_start_finish_cycle_id: searchStartFinishCycleId,
  },
});

export const buildAdditionalInfoNearbyMap = ({
  clickType,
  hotelId,
  isMapFullView,
  poiInfo,
}) => ({
  nearby_map: {
    is_map_full_view: isMapFullView,
    hotel_id: hotelId,
    click_type: clickType,
    poi_info: poiInfo,
  },
});

export const buildAdditionalInfoRecommendHotelsChipMessage = ({ type }) => ({
  recommend_chip_info: {
    type: type ? type.toUpperCase() : 'UNSET_CHIP_TYPE',
  },
});

export const buildAdditionalInfoDetailRatesFilterClick = (filterValue) => ({
  detail_rates_filter_click: Object.keys(RATES_FILTER_TYPE).reduce(
    (acc, curr) => {
      acc[RATES_FILTER_TYPE[curr]] = !!filterValue[curr];
      return acc;
    },
    {},
  ),
});

export const buildAdditionalInfoBaseRoomTypeFilterSelected = (
  roomType,
  filterStatus,
) => ({
  base_room_type_filter_info: {
    base_room_type: roomType && BASE_ROOM_TYPE[roomType.toUpperCase()],
    is_selected: filterStatus,
  },
});

export const buildAdditionalInfoAutoSuggestPoiClick = ({ cityId, poiId }) => ({
  auto_suggest_poi_click: { city_id: cityId, poi_id: poiId },
});

export const buildAdditionalInfoAutoSuggestPois = (suggestion) => {
  let poi;
  const poiList = AUTO_SUGGEST_POI_LIST.reduce((acc, curr, index) => {
    poi = suggestion.pois[index] || {};
    acc[curr] = poi.entityId || '';
    return acc;
  }, {});
  return {
    auto_suggest_pois: {
      city_id: suggestion.entityId,
      ...poiList,
    },
  };
};

export const buildAdditionInfoBookingProcessState = ({
  hotelId,
  messageState,
  policyChanedType,
  priceChangedInfo,
  processState,
}) => ({
  booking_process_state_info: {
    hotel_id: parseInt(hotelId, 10),
    booking_process_state: BOOKING_PROCESS_STATE[processState],
    message_state: messageState ? MESSAGE_STATE[messageState] : undefined,
    price_changed_info: priceChangedInfo
      ? {
          prev_price: {
            currency: priceChangedInfo.currency,
            amount: Math.round(priceChangedInfo.expectedPrice),
            unit: 'WHOLE',
          },
          changed_price: {
            currency: priceChangedInfo.currency,
            amount: Math.round(priceChangedInfo.diffAmount),
            unit: 'WHOLE',
          },
          price_changed_type: PRICE_CHANGED_TYPE[priceChangedInfo.type],
        }
      : undefined,
    policy_changed_type: policyChanedType
      ? POLICY_CHANGED_TYPE[policyChanedType.toUpperCase()]
      : POLICY_CHANGED_TYPE.UNSET_POLICY_CHANGED_TYPE,
  },
});

export const buildAdditionInfoBookingPerformed = ({
  bookingId,
  bookingStatus,
  cug,
  currency,
  hotelId,
  partnerId,
  price,
  roomRateId,
  searchId,
}) => {
  const cugType = (cug && cug.type && cug.type.toUpperCase()) || null;

  return {
    booking_performed_info: {
      booking_id: bookingId,
      booking_status: BOOKING_STATUS[bookingStatus],
      hotel_id: parseInt(hotelId, 10),
      partner_id: partnerId,
      price: price && {
        currency,
        amount: Math.round(price),
        unit: 'WHOLE',
      },
      room_rate_id: roomRateId,
      search_id: searchId,
      cug_type: CUG_TYPE[cugType] ? CUG_TYPE[cugType] : CUG_TYPE.UNSET_CUG_TYPE,
      cug_deal_percentage: cug && cug.discountPercentage,
    },
  };
};

export const buildAdditionalInfoMapActiveDayView = (clickArea) => ({
  map_active_day_view: {
    click_area: clickArea,
  },
});

export const buildAdditionalInfoPoiMenuSelectDayView = ({
  clickArea,
  poiId,
  poiType,
}) => ({
  poi_menu_select_day_view: {
    poi_id: poiId,
    poi_type: poiType,
    click_area: clickArea,
  },
});

export const buildAdditionalInfoHotelPriceStatusMessage = ({
  hasRate,
  hotelId,
}) => ({
  hotel_prices_status: {
    hotel_id: parseInt(hotelId, 10),
    has_price: hasRate,
  },
});

export const buildAdditionalInfoUnifiedPaymentPlatformMessage = ({
  action,
  bookingId,
  partnerId,
  status,
}) => ({
  unified_payment_integration_status: {
    current_step: UNIFIED_INTEGRATION_STEP_TYPE[action.toUpperCase()],
    status: ACTION_STATUS_YPE[status],
    partner_id: PARTNER_ID[partnerId.toUpperCase()],
    booking_id: bookingId,
  },
});

export const buildAdditionalInfoPreCheckState = ({
  cugType,
  hotelId,
  isLoggedIn,
  preCheckState,
  prevTrackingId,
  priceConsistencyCheck,
  rateId,
  roomId,
  searchCycleId,
  trackingId,
}) => ({
  checkout_form_pre_check_state: {
    cug_type: cugType ? CUG_TYPE[cugType.toUpperCase()] : undefined,
    hotel_id: parseInt(hotelId, 10),
    pre_check_state: MESSAGE_STATE[preCheckState],
    room_id: roomId,
    rate_id: rateId,
    prev_tracking_id: prevTrackingId,
    is_price_consistency_check: priceConsistencyCheck,
    tracking_id: trackingId,
    is_logged_in: isLoggedIn,
    search_cycle_id: searchCycleId,
  },
});

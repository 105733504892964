import isPast from 'date-fns/isPast';

import { HOTEL_BOOKING_LIMITS } from '../../../constants';
import { parseDate, differenceDaysLength, stayLength } from '../../date-utils';

const isStayLengthValid = (
  checkIn,
  checkOut,
  maxStayLength = HOTEL_BOOKING_LIMITS.MAX_STAY_LENGTH,
) => {
  const length = stayLength({ checkIn, checkOut });
  return length >= 1 && length <= maxStayLength;
};

const isPastBeyondYesterday = (date) =>
  isPast(parseDate(date)) &&
  differenceDaysLength(parseDate(date), Date.now()) < -1;

const isPastBeyondToday = (date) =>
  isPast(parseDate(date)) &&
  differenceDaysLength(parseDate(date), Date.now()) < 0;

// eslint-disable-next-line import/prefer-default-export
export const areStayDatesValid = ({ checkIn, checkOut } = {}) => {
  if (!checkIn || !checkOut) {
    return false;
  }

  if (isPastBeyondYesterday(checkIn) || isPastBeyondToday(checkOut)) {
    return false;
  }

  if (!isStayLengthValid(checkIn, checkOut)) {
    return false;
  }

  if (
    differenceDaysLength(parseDate(checkIn), Date.now()) >
    HOTEL_BOOKING_LIMITS.MAX_DAYS_IN_ADVANCE
  ) {
    return false;
  }

  return true;
};

import type {
  StatsDEvent,
  OneOfTag,
  StatsDClientType,
  StatsDProps,
} from '../types';

class StatsDClient implements StatsDClientType {
  buffer: StatsDEvent[];

  maxBufferSize: number;

  flushInterval: number;

  url: string;

  constructor({
    baseUrl,
    flushInterval = 10000,
    maxBufferSize = 20,
  }: StatsDProps) {
    this.buffer = [];
    this.maxBufferSize = maxBufferSize;
    this.flushInterval = flushInterval;
    this.url = `${baseUrl}/metrics/v1/metrics`;

    setInterval(() => this.flush(), this.flushInterval);
    if (typeof window !== 'undefined' && window.addEventListener) {
      window.addEventListener('unload', () => this.flush());
    }
  }

  increment(metric: string, value: number = 1, tags: OneOfTag = {}) {
    this.log(metric, value, 'increment', tags);
  }

  gauge(metric: string, value: number, tags: OneOfTag = {}) {
    this.log(metric, value, 'gauge', tags);
  }

  timing(metric: string, value: number, tags: OneOfTag = {}) {
    this.log(metric, value, 'timing', tags);
  }

  log(
    metric: string,
    value: number,
    type: 'increment' | 'gauge' | 'timing',
    tags: OneOfTag,
  ) {
    const event = {
      name: metric,
      value,
      type,
      tags,
    };
    this.buffer.push(event);
    if (this.buffer.length >= this.maxBufferSize) {
      this.flush();
    }
  }

  flush() {
    if (this.buffer.length > 0) {
      this.send({ metrics: this.buffer });
      this.buffer = [];
    }
  }

  send(payload: { metrics: StatsDEvent[] }) {
    if (navigator && navigator.sendBeacon) {
      navigator.sendBeacon(this.url, JSON.stringify(payload));
    }
  }
}

export default StatsDClient;

import { GLOBAL } from 'saddlebag-browser';

const isDevEnv = () => process.env.NODE_ENV === 'development';
const isProdEnv = () => process.env.NODE_ENV === 'production';
const isTestEnv = () => process.env.NODE_ENV === 'test';

const isBrowser = () => {
  const $window = GLOBAL.getWindow();
  return typeof $window === 'object' && Object.keys($window).length > 0;
};

export { isTestEnv, isProdEnv, isDevEnv, isBrowser };

import PropTypes from 'prop-types';
import { Component as ReactComponent } from 'react';
import type { ReactNode } from 'react';

import type { ElementEventTrackerReturn } from '../types';

type Props = {
  elementEventTracker: ElementEventTrackerReturn;
  children: ReactNode;
};

const ContextTypes = {
  elementEventTracker: PropTypes.shape({
    trackMiniEvent: PropTypes.func.isRequired,
    trackHotelsAction: PropTypes.func.isRequired,
    trackExperimentChockPoint: PropTypes.func.isRequired,
  }),
};

class ElementEventTrackerContext extends ReactComponent<Props> {
  static childContextTypes = ContextTypes;

  getChildContext() {
    const { elementEventTracker } = this.props;
    return { elementEventTracker };
  }

  render() {
    return <> {this.props.children} </>;
  }
}

export default ElementEventTrackerContext;

import { type MouseEvent } from 'react';

import { withRtlSupport } from '@skyscanner/backpack-web/bpk-component-icon';
import BpkSmallChevronLeftIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/chevron-left';
import BpkSmallCloseIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/close';
import BpkNavigationBar, {
  BpkNavigationBarIconButton,
} from '@skyscanner/backpack-web/bpk-component-navigation-bar';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text/src/BpkText';
import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import { withI18n } from '../../../services/i18n';

import type { I18nShape } from '../../../services/i18n';

import STYLES from './BackHeader.scss';

type Props = {
  buttonClassName?: string;
  className?: string;
  i18n: I18nShape;
  onClick: (event: MouseEvent<HTMLElement>) => void;
  opacity?: number;
  showClose?: boolean;
  title?: string;
};

const AlignedChevronLeft = withRtlSupport(BpkSmallChevronLeftIcon);
const AlignedClose = withRtlSupport(BpkSmallCloseIcon);

const cls = cssModules(STYLES);

const BackHeader = ({
  buttonClassName = undefined,
  className = undefined,
  i18n: { translate },
  onClick,
  opacity = 1,
  showClose = false,
  title = undefined,
}: Props) => {
  const CloseIcon = showClose ? AlignedClose : AlignedChevronLeft;

  return (
    <BpkNavigationBar
      id="back-header"
      className={cls('BackHeader', className)}
      title={
        title && (
          <BpkText
            tagName="p"
            textStyle={TEXT_STYLES.bodyDefault}
            style={{ opacity }}
          >
            {title}
          </BpkText>
        )
      }
      leadingButton={
        <BpkNavigationBarIconButton
          data-testid="back-header"
          className={cls('BackHeader__button', buttonClassName)}
          onClick={onClick}
          icon={CloseIcon}
          label={translate('PageErrorBoundary_ErrorCard_button')}
        />
      }
    />
  );
};

export default withI18n(BackHeader);

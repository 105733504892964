import { Component } from 'react';

import { BrowserRouter } from 'react-router-dom';

import RedirectingSearchBarWithAboutBanner from '../../hotels-utils/components-search-controls-adjacent/Common/RedirectingSearchBarWithAboutBanner';
import UserContextProvider from '../../hotels-utils/components-search-controls-adjacent/UserContextProvider';
import { I18nProvider } from '../../hotels-utils/services/i18n';
import I18nService from '../../hotels-utils/services/i18n/i18n-service';
import logger from '../../hotels-utils/services/logger';
import {
  getUtmSource,
  trafficSource as getTrafficSource,
} from '../../hotels-utils/services/traffic-source';
import { getCurrentQueryParam } from '../../hotels-utils/services/url/url';
import ApplicationMetrics from '../../hotels-utils/skyscanner-application/application-metrics';
import MetricsContext from '../../hotels-utils/skyscanner-application/application-metrics/MetricsContext';
import ElementEventTrackerContext from '../../hotels-utils/skyscanner-application/element-events';
import ElementEventTracker from '../../hotels-utils/skyscanner-application/element-events/element-event-tracker';
import { buildObserverClient } from '../../hotels-utils/skyscanner-application/observer-client';

import type {
  ElementEventTrackerReturn,
  I18nShape,
  MetricsType,
} from '../../hotels-utils/skyscanner-application/types';
import type { HotelsSearchControlsWrapperProps } from 'common-types/types/hotels-components/HotelSearchControlsWrapperProps';

class HotelSearchControlsWrapper extends Component<HotelsSearchControlsWrapperProps> {
  i18n?: I18nShape;

  metrics?: MetricsType;

  elementEventTracker?: ElementEventTrackerReturn;

  static defaultProps = {
    openCheckinSelector: false,
    filters: undefined,
  };

  constructor(props: HotelsSearchControlsWrapperProps) {
    super(props);
    const {
      cldr,
      environment,
      impressionId,
      pageName,
      region,
      statsD,
      translations,
      userContext,
    } = props.contextProps;

    this.i18n = I18nService(
      {
        translations,
        cldr,
        culture: props.culture,
      },
      logger,
    );

    if (typeof window === 'undefined') return;

    const source = getCurrentQueryParam('source');
    const utmSource = getUtmSource(getCurrentQueryParam('utm_source'));
    const observerClient = buildObserverClient({ environment });
    const trafficSource = getTrafficSource({ isBananaReferral: false, source });

    this.elementEventTracker = ElementEventTracker({
      observerClient,
      trafficSource,
      utmSource,
      device: props.contextProps.userContext.device,
      pageType: userContext.pageType,
      impressionId,
    });

    this.metrics = new ApplicationMetrics({
      observerClient,
      deviceInfo: props.contextProps.userContext.device,
      trafficSource,
      elementEventTracker: this.elementEventTracker,
      pageName,
      region,
      statsD,
    });
  }

  render() {
    if (typeof window === 'undefined') return null;
    if (typeof this.elementEventTracker === 'undefined') return null;
    if (typeof this.metrics === 'undefined') return null;
    if (typeof this.i18n === 'undefined') return null;

    const { contextProps, ...mainProps } = this.props;
    return (
      <BrowserRouter>
        <ElementEventTrackerContext
          elementEventTracker={this.elementEventTracker}
        >
          <MetricsContext metrics={this.metrics}>
            <I18nProvider value={this.i18n}>
              <UserContextProvider value={contextProps.userContext}>
                <RedirectingSearchBarWithAboutBanner {...mainProps} />
              </UserContextProvider>
            </I18nProvider>
          </MetricsContext>
        </ElementEventTrackerContext>
      </BrowserRouter>
    );
  }
}

export default HotelSearchControlsWrapper;

import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text/src/BpkText';
import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import {
  getSubheadingValue,
  getSuggestionIcon,
  getSuggestionValue,
} from '../../searchControlHelper';

import type { PopularDestinationItemShape, Maybe } from '../../../../types';

import STYLES from './PopularDestination.scss';

type Props = {
  index: number;
  onSuggestionSelected: Function;
  onBlur: Function;
  popular: PopularDestinationItemShape;
  isMobile?: boolean;
  onSuggestionsClearRequested?: Maybe<Function>;
};

const cls = cssModules(STYLES);

const PopularDestination = ({
  index,
  isMobile = false,
  onBlur,
  onSuggestionSelected,
  onSuggestionsClearRequested = undefined,
  popular,
}: Props) => {
  const Icon = getSuggestionIcon(popular);
  return (
    <div
      className={cls('PopularDestination')}
      key={popular.entityId}
      role="button"
      onKeyUp={() => {}}
      tabIndex={0}
      onClick={(e) => {
        e.stopPropagation();
        onSuggestionSelected(
          {},
          {
            suggestion: popular,
            suggestionIndex: index,
            suggestionValue: popular.entity,
            method: 'click',
            isPopularDestination: true,
          },
        );
        onBlur(e, {
          highlightedSuggestion: popular,
        });

        if (onSuggestionsClearRequested) {
          onSuggestionsClearRequested();
        }
      }}
    >
      {isMobile ? (
        getSuggestionValue(popular)
      ) : (
        <>
          <Icon className={cls('PopularDestination__icon')} />
          <div className={cls('PopularDestination__inner')}>
            <BpkText
              tagName="p"
              textStyle={TEXT_STYLES.bodyDefault}
              className={cls('PopularDestination__value')}
            >
              {getSuggestionValue(popular)}
            </BpkText>
            <BpkText
              tagName="p"
              textStyle={TEXT_STYLES.caption}
              className={cls('PopularDestination__subHeading')}
            >
              {/* @ts-ignore */}
              {getSubheadingValue(popular)}
            </BpkText>
          </div>
        </>
      )}
    </div>
  );
};

export default PopularDestination;

import { useState, useEffect } from 'react';
import type { ReactElement } from 'react';

type Props = {
  enabled: boolean;
  children: ReactElement;
};

export default function SplitRenderingHere({ children, enabled }: Props) {
  const [shouldRenderThisTime, setShouldRenderThisTime] = useState(false);

  useEffect(() => {
    setTimeout(() => setShouldRenderThisTime(true), 0);
  }, []);

  if (!enabled) {
    return children;
  }

  if (shouldRenderThisTime) {
    return children;
  }

  return null;
}

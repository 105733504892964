import SearchIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/search';
import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import GuestsRoomsChildrenSelect from '../../Common/GuestsRoomsChildrenSelect';
import DateRangeSelector from '../DateRangeSelector';
import DestinationSelector from '../DestinationSelector';

import type { AutosuggestSuggestionOrPopularDestinationShape } from '../../../types';
import type { DestinationShape } from 'common-types/types/hotels-components/types';

import STYLES from './ChappedLayout.scss';

const cls = cssModules(STYLES);

type Props = {
  destination: DestinationShape;
  checkInDate: Date;
  checkOutDate: Date;
  destinationLabel: string;
  searchButtonLabel: string;
  childrenAges: string[];
  adults: number;
  rooms: number;
  suggestions: AutosuggestSuggestionOrPopularDestinationShape;
  onClearSuggestions: Function;
  onSuggestionSelected: Function;
  onSuggestionsFetchRequested: Function;
  onDatesChanged: Function;
  onSubmit: any;
  getGuestsRoomsChildren: Function;
  lightLabel?: boolean;
  arrangeInline?: boolean;
};

const ChappedLayout = ({
  adults,
  arrangeInline = false,
  checkInDate,
  checkOutDate,
  childrenAges,
  destination,
  destinationLabel,
  getGuestsRoomsChildren,
  lightLabel = false,
  onClearSuggestions,
  onDatesChanged,
  onSubmit,
  onSuggestionSelected,
  onSuggestionsFetchRequested,
  rooms,
  searchButtonLabel,
  suggestions,
}: Props) => (
  <div className={cls('ChappedLayout')}>
    <div className={cls('ChappedLayout__inputs')}>
      <DestinationSelector
        className={cls('ChappedLayout__location')}
        inputClassName={cls('ChappedLayout__input')}
        destinationLabel={destinationLabel}
        destination={destination}
        lightLabel={lightLabel}
        onSuggestionSelected={onSuggestionSelected}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onClearSuggestions}
        suggestions={suggestions}
        arrangeInline={arrangeInline}
      />
      <button
        type="button"
        className={cls('ChappedLayout__searchButton')}
        onClick={onSubmit}
        aria-label={searchButtonLabel}
      >
        <SearchIcon />
      </button>
    </div>
    <div
      className={cls('ChappedLayout__inputsDatesGuest')}
      data-test-id="search-controls-datesguest"
    >
      <DateRangeSelector
        // @ts-ignore
        lightLabel={lightLabel}
        checkInDate={checkInDate}
        checkOutDate={checkOutDate}
        onDatesChanged={onDatesChanged}
        showNights
      />
    </div>
    <GuestsRoomsChildrenSelect
      className={cls('ChappedLayout__guestsRoomsChildren')}
      lightLabel={lightLabel}
      adults={adults}
      rooms={rooms}
      childrenAges={childrenAges}
      getGuestsRoomsChildren={getGuestsRoomsChildren}
    />
  </div>
);

export default ChappedLayout;

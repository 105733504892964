import { withRtlSupport } from '@skyscanner/backpack-web/bpk-component-icon';
import BpkAdultIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/adult';
import BpkRecentSearchIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/recent-searches';
import BpkAdultIconSm from '@skyscanner/backpack-web/bpk-component-icon/sm/adult';
import BpkArrowRightIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/long-arrow-right';
import BpkRecentSearchIconSm from '@skyscanner/backpack-web/bpk-component-icon/sm/recent-searches';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text/src/BpkText';
import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import { DATE_FORMAT, withI18n } from '../../../../services/i18n';
import { getSubheadingValue } from '../../searchControlHelper';

import type { I18nShape } from '../../../../services/i18n';
import type { RecentSearchShape } from '../../../../types';
import type { DestinationShape } from 'common-types/types/hotels-components/types';

import STYLES from './RecentSearchItem.scss';

type Props = {
  i18n: I18nShape;
  item: RecentSearchShape;
  index: number;
  onSuggestionSelected: Function;
  onSuggestionsClearRequested?: Function;
  onBlur: Function;
  isMobile: boolean;
};

const cls = cssModules(STYLES);

const getAdultIcon = (isMobile: boolean) =>
  isMobile ? withRtlSupport(BpkAdultIconSm) : withRtlSupport(BpkAdultIcon);

const getRecentSearchIcon = (isMobile: boolean) =>
  isMobile
    ? withRtlSupport(BpkRecentSearchIconSm)
    : withRtlSupport(BpkRecentSearchIcon);

const ArrowRightIcon = withRtlSupport(BpkArrowRightIcon);

export const getRecentSearchValue = (
  destination: DestinationShape,
  isMobile: boolean,
) => {
  const entityValue = destination.entity;
  const subValues = getSubheadingValue(destination);
  return !isMobile && subValues ? `${entityValue}, ${subValues}` : entityValue;
};

const RecentSearchItem = ({
  i18n,
  index,
  isMobile,
  item,
  onBlur,
  onSuggestionSelected,
  onSuggestionsClearRequested = undefined,
}: Props) => {
  const {
    adults,
    checkInDate,
    checkOutDate,
    childrenAges,
    destination,
    rooms,
  } = item;
  if (!destination) {
    return null;
  }
  const guestNum = adults + childrenAges.length;
  const formattedCheckIn = i18n.formatDate(
    checkInDate,
    DATE_FORMAT.MONTH_DAY_NO_WEEK_ABBR,
  );
  const formattedCheckOut = i18n.formatDate(
    checkOutDate,
    DATE_FORMAT.MONTH_DAY_NO_WEEK_ABBR,
  );

  const RecentSearchIcon = getRecentSearchIcon(isMobile);
  const AdultIcon = getAdultIcon(isMobile);

  const mainValue = (
    <div
      className={cls('RecentSearchItem__recentSearch')}
      key={index}
      role="button"
      onKeyUp={() => {}}
      tabIndex={0}
      onClick={(e) => {
        e.stopPropagation();
        onSuggestionSelected(
          {},
          {
            suggestion: destination,
            suggestionIndex: index,
            suggestionValue: destination.entity,
            method: 'click',
            adults,
            checkInDate,
            checkOutDate,
            childrenAges,
            rooms,
            isFromRecentSearch: true,
          },
        );
        onBlur(e, { highlightedSuggestion: destination });
        if (onSuggestionsClearRequested) {
          onSuggestionsClearRequested();
        }
      }}
    >
      <RecentSearchIcon
        className={cls('RecentSearchItem__recentSearch--icon')}
      />
      <BpkText
        textStyle={TEXT_STYLES.bodyDefault}
        className={cls('RecentSearchItem__recentSearch--entityItem')}
      >
        {getRecentSearchValue(destination, isMobile)}
      </BpkText>
      <BpkText
        textStyle={TEXT_STYLES.bodyDefault}
        className={cls('RecentSearchItem__recentSearch--item')}
      >
        {`${formattedCheckIn} - ${formattedCheckOut}`}
      </BpkText>
      <BpkText
        textStyle={TEXT_STYLES.bodyDefault}
        className={cls('RecentSearchItem__recentSearch--adultItem')}
      >
        <AdultIcon />
        {guestNum}
      </BpkText>
      {!isMobile && (
        <BpkText
          textStyle={TEXT_STYLES.bodyDefault}
          className={cls('RecentSearchItem__recentSearch--item')}
        >
          {i18n.translatePlural(
            'SelectionSummary_label_rooms',
            rooms,
            'number',
          )}
        </BpkText>
      )}
    </div>
  );

  return (
    <>
      {isMobile ? (
        <div className={cls('RecentSearchItem__hiddenAboveMobile')}>
          {mainValue}
          <div className={cls('RecentSearchItem__rectangleShape')} />
          <div className={cls('RecentSearchItem__arrowIcon')}>
            <ArrowRightIcon />
          </div>
        </div>
      ) : (
        mainValue
      )}
    </>
  );
};

export default withI18n(RecentSearchItem);

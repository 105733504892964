import { addDays } from 'date-fns/addDays';
import { startOfDay } from 'date-fns/startOfDay';

import { HOTEL_BOOKING_LIMITS } from '../../../constants';

import { areStayDatesValid } from './are-stay-dates-valid';

export const applyCheckOutDateRestrictions = (checkIn, checkOut) => {
  if (!areStayDatesValid({ checkIn, checkOut })) {
    return addDays(checkIn, 1);
  }

  return checkOut;
};

export const applyCheckInDateRestrictions = (checkIn, checkOut) => {
  if (!areStayDatesValid({ checkIn, checkOut })) {
    return addDays(checkOut, -1);
  }

  return checkIn;
};

export const applyNumGuestsRestrictions = (numGuests, numRooms) =>
  Math.max(numGuests, numRooms);

export const applyNumRoomsRestrictions = (numGuests, numRooms) =>
  Math.min(numGuests, numRooms);

export const minCheckInDate = () => startOfDay(new Date());

export const minCheckOutDate = () => startOfDay(addDays(new Date(), 1));

export const maxCheckInDate = () =>
  addDays(minCheckInDate(), HOTEL_BOOKING_LIMITS.MAX_DAYS_IN_ADVANCE);

export const maxCheckOutDate = () =>
  addDays(minCheckOutDate(), HOTEL_BOOKING_LIMITS.MAX_DAYS_IN_ADVANCE + 1);

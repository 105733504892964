import { Component } from 'react';
import type { ReactElement, ContextType } from 'react';

import debounce from 'lodash/debounce';

import HotelsPropsContext from '../../../../components/SearchControlsWrapper/HotelsPropsContext';
import {
  ACTION_TYPE,
  COMPONENT_ACTION,
  COMPONENT_NAME,
} from '../../../../constants';
import { logAutoSuggestHotelEvent } from '../../../../logMiniEvent';
import logMiniEventHelper from '../../../../mini-event/logMiniEventHelper';
import { withI18n } from '../../../services/i18n';
import logger from '../../../services/logger';
import { withMetrics } from '../../../skyscanner-application/application-metrics';
import { buildResultsUpdatedMessage } from '../../../skyscanner-application/minievents/autosuggest';
import { buildAdditionalInfoAutoSuggestPois } from '../../../skyscanner-application/minievents/hotels-action';
import { withUserContextProvider } from '../../UserContextProvider';
import getDeviceType from '../../get-device-type';

import AutosuggestGateway from './autosuggest-gateway';

import type { AutosuggestGatewayProps } from './types';
import type { I18nShape, Culture } from '../../../services/i18n';
import type { MetricsType } from '../../../skyscanner-application/types';
import type {
  Maybe,
  AutosuggestSuggestionShape,
  PopularDestinationShape,
  AutosuggestSuggestionOrPopularDestinationShape,
} from '../../../types';
import type { UserContextShape } from 'common-types/types/hotels-components/types';

type Props = {
  i18n: I18nShape;
  children: (
    suggestions: AutosuggestSuggestionOrPopularDestinationShape,
    onClearSuggestions: () => void,
  ) => ReactElement;
  input?: Maybe<string>;
  metrics: MetricsType;
  userContext: UserContextShape;
};

type State = {
  input: Maybe<string>;
  suggestions: AutosuggestSuggestionOrPopularDestinationShape;
  emptyInputSuggestions: AutosuggestSuggestionOrPopularDestinationShape;
};

const AUTOSUGGEST_RESULTS_UPDATED_DEBOUNCE_MILLI = 650;

class AutosuggestDataProvider extends Component<Props, State> {
  static contextType = HotelsPropsContext;

  autosuggestGateway: AutosuggestGatewayProps;

  static defaultProps = {
    input: undefined,
  };

  state: State = {
    input: this.props.input,
    suggestions: [],
    emptyInputSuggestions: [],
  };

  buildAndSendSelectionUpdatedEvent = debounce(
    (query: Maybe<string>, numResults: number, culture: Culture) => {
      const resultsUpdatedMessage = buildResultsUpdatedMessage(
        query,
        numResults,
        culture,
      );
      logAutoSuggestHotelEvent(resultsUpdatedMessage);
      logger.event(COMPONENT_ACTION.HOTEL_AUTOSUGGEST.RESULTS_UPDATED);
    },
    AUTOSUGGEST_RESULTS_UPDATED_DEBOUNCE_MILLI,
  );

  constructor(props: Props, context: ContextType<typeof HotelsPropsContext>) {
    super(props);
    const {
      i18n: { culture },
      userContext: { device, pageType, userPreferences },
    } = props;
    const { autosuggestExpVal } = context || {};

    const platform = getDeviceType(device);

    this.autosuggestGateway = AutosuggestGateway({
      culture,
      platform,
      pageType,
      utid: userPreferences.utid,
      autosuggestExpVal,
    });
  }

  componentDidMount() {
    this.autosuggestGateway.subscribe({
      next: (suggestions) => {
        this.setState({
          suggestions,
          emptyInputSuggestions: suggestions,
        });

        const {
          i18n: { culture },
          input: query,
        } = this.props;
        const numResults = suggestions.length;
        const [firstItem] = suggestions;
        this.buildAndSendSelectionUpdatedEvent(query, numResults, culture);

        const item = firstItem as AutosuggestSuggestionShape;
        if (item.pois && item.pois.length > 0) {
          logMiniEventHelper({
            action_type: ACTION_TYPE.COMPONENT_CLICKED,
            component_name: COMPONENT_NAME.HOTEL_SEARCH_CONTROLS,
            component_action:
              COMPONENT_ACTION.HOTEL_SEARCH_CONTROLS.AUTO_SUGGEST_POIS_RENDER,
            hotel_search_controls:
              buildAdditionalInfoAutoSuggestPois(firstItem),
          });
        }
      },

      error: () => {
        this.setState({ suggestions: [] });
      },
    });
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const { emptyInputSuggestions, input: inputFromState } = prevState;
    const suggestion = emptyInputSuggestions[0] as PopularDestinationShape;
    if (
      nextProps.input === '' &&
      !inputFromState &&
      suggestion &&
      suggestion.popularDestinations &&
      suggestion.popularDestinations.length > 0
    ) {
      return { suggestions: emptyInputSuggestions };
    }
    return null;
  }

  componentDidUpdate({ input: prevInput }: Pick<Props, 'input'>) {
    const { input, metrics } = this.props;
    if (prevInput?.trim() === input?.trim()) {
      return;
    }

    this.autosuggestGateway.input({ value: input, metrics });
  }

  componentWillUnmount() {
    this.autosuggestGateway.unsubscribe();
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  render() {
    const { suggestions } = this.state;

    const { children } = this.props;
    return <>{children(suggestions, this.onSuggestionsClearRequested)}</>;
  }
}

export default withMetrics(
  withI18n(withUserContextProvider(AutosuggestDataProvider)),
);

import type { MouseEventHandler } from 'react';

import BpkLargeCalendarIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/calendar';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text/src/BpkText';
import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import { withI18n, DATE_FORMAT } from '../../../../services/i18n';

import type { I18nShape } from '../../../../services/i18n';

import STYLES from './DateRange.scss';

const cls = cssModules(STYLES);

type Props = {
  i18n: I18nShape;
  onClickLink: MouseEventHandler<HTMLDivElement>;
  checkInDate: Date;
  checkOutDate: Date;
};

const DateRange = ({
  checkInDate,
  checkOutDate,
  i18n: { formatDate },
  onClickLink,
}: Props) => (
  <div
    role="button"
    tabIndex={0}
    onKeyUp={() => {}}
    onClick={onClickLink}
    className={cls('DateRange')}
  >
    <BpkLargeCalendarIcon className={cls('DateRange__icon')} />
    <button type="button">
      <BpkText tagName="p" textStyle={TEXT_STYLES.caption}>
        {formatDate(checkInDate, DATE_FORMAT.MONTH_DAY_NO_WEEK_ABBR)}
      </BpkText>
      <BpkText tagName="p" textStyle={TEXT_STYLES.caption}>
        {formatDate(checkOutDate, DATE_FORMAT.MONTH_DAY_NO_WEEK_ABBR)}
      </BpkText>
    </button>
  </div>
);

export default withI18n(DateRange);

import { Component } from 'react';

type Props = {
  children: any;
  fetchData: any;
  updateOn?: any;
  stream?: boolean;
};

type State = {
  result: any;
};

const LoadingResult = () => ({
  isLoading: true,
  value: null,
  error: null,
});

const SuccessResult = (value: any) => ({
  isLoading: false,
  value,
  error: null,
});

const ErrorResult = (error: Object) => ({
  isLoading: false,
  value: null,
  error,
});

class DataProvider extends Component<Props, State> {
  static defaultProps = {
    updateOn: undefined,
    stream: false,
  };

  mounted: boolean;

  constructor(props: Props) {
    super(props);

    this.mounted = false;
    this.state = {
      result: LoadingResult(),
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.fetchData();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.updateOn !== this.props.updateOn) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setResult(result: any) {
    if (this.mounted) {
      this.setState({ result });
    }
  }

  fetchData() {
    this.setResult(LoadingResult());
    if (this.props.stream) {
      const eventEmitter = this.props.fetchData();
      eventEmitter.addListener('data', (data: any) =>
        this.setResult(SuccessResult(data)),
      );
      eventEmitter.addListener('error', (err: Error) =>
        this.setResult(ErrorResult(err)),
      );
      eventEmitter.addListener('timeout', () =>
        this.setResult(ErrorResult({ error: 'timeout' })),
      );
    } else {
      this.props
        .fetchData()
        .then((value: any) => this.setResult(SuccessResult(value)))
        .catch((err: Error) => this.setResult(ErrorResult(err)));
    }
  }

  render() {
    const { result } = this.state;
    return <>{this.props.children(result)}</>;
  }
}

export default DataProvider;

import PropTypes from 'prop-types';
import { Component } from 'react';

import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import { parseStayProp } from '../../../components/HotelsCommon/utils';
import SearchControls from '../../components-search-controls-adjacent/SearchControls';
import { withI18n } from '../../services/i18n';
import { I18nShape } from '../../services/i18n/i18n-shape';
import { StayShape, DestinationShape } from '../../shapes';

import STYLES from './SearchBar.scss';

const cls = cssModules(STYLES);

class SearchBar extends Component {
  constructor(props) {
    super(props);

    const { arrangeInline } = props;

    this.state = {
      expanded: arrangeInline ? true : props.expanded,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isMobile &&
      !prevState.expanded &&
      this.props.isMobile !== prevProps.isMobile
    ) {
      this.setState({ expanded: true });
    }
  }

  onSearchControlsSubmit = (params) => {
    const { onSearchControlsSubmit } = this.props;
    onSearchControlsSubmit(params);
  };

  destinationLabel = () => {
    const {
      i18n: { translate },
    } = this.props;

    return translate('SearchControls_label_whereDoYouWantToStay');
  };

  render() {
    const { arrangeInline, destination, isMobile, stay } = this.props;

    const parsedStay = parseStayProp(stay);

    return (
      <div
        className={cls(
          'SearchBar__searchControls',
          arrangeInline && 'SearchBar__searchControlsExpand',
        )}
      >
        <SearchControls
          stay={parsedStay}
          destination={destination}
          arrangeInline={arrangeInline}
          layout={isMobile ? 'horizontal' : undefined}
          showPreference={!arrangeInline}
          isMobile={isMobile}
          onSubmit={this.onSearchControlsSubmit}
          destinationLabel={this.destinationLabel()}
        />
      </div>
    );
  }
}

SearchBar.propTypes = {
  i18n: I18nShape.isRequired,
  onSearchControlsSubmit: PropTypes.func.isRequired,
  destination: DestinationShape,
  stay: StayShape.isRequired,
  expanded: PropTypes.bool,
  arrangeInline: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
};

SearchBar.defaultProps = {
  destination: null,
  expanded: false,
  arrangeInline: false,
};

export default withI18n(SearchBar);

import { Component } from 'react';
import type { ReactNode } from 'react';

import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text/src/BpkText';
import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import BackHeader from './BackHeader';

import STYLES from './FullScreenWrapper.scss';

type Props = {
  children: ReactNode;
  onClose: Function;
  onCancel?: Function;
  showCloseHeader?: boolean;
  title?: string;
  headline?: string;
  headerClassName?: string;
  contentClassName?: string;
  footerClassName?: string;
  footer?: ReactNode;
  renderHeader?: Function;
  renderFooter?: Function;
};

type State = {
  opacity: number;
};

const defaultProps = {
  onCancel: undefined,
  title: undefined,
  headline: undefined,
  headerClassName: undefined,
  contentClassName: undefined,
  footerClassName: undefined,
  showCloseHeader: false,
  footer: null,
  renderHeader: undefined,
  renderFooter: undefined,
};

const cls = cssModules(STYLES);

// [0.00, 0.01, 0.02, ..., 0.99]
const INTERSECTION_THRESHOLD = new Array(100)
  .fill(100)
  .map((item, index) => index / item);

class FullScreenWrapper extends Component<Props, State> {
  static defaultProps = defaultProps;

  shouldObservable: boolean;

  observer: IntersectionObserver | null;

  constructor(props: Props) {
    super(props);
    this.shouldObservable = !!(
      props.title &&
      !props.headline &&
      typeof window !== 'undefined' &&
      window.IntersectionObserver
    );
    this.observer = this.getObserver();
    this.state = {
      opacity: this.shouldObservable ? 0 : 1,
    };
  }

  getObserver = () => {
    if (!this.shouldObservable) {
      return null;
    }

    return new window.IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          this.setState({
            opacity: 1 - entry.intersectionRatio,
          });
        });
      },
      {
        threshold: INTERSECTION_THRESHOLD,
      },
    );
  };

  render() {
    const {
      children,
      contentClassName,
      footer: propsFooter,
      footerClassName,
      headerClassName,
      headline,
      onCancel,
      onClose,
      renderFooter,
      renderHeader,
      showCloseHeader,
      title,
    } = this.props;
    const footer = renderFooter ? renderFooter(onClose) : propsFooter;
    const header = renderHeader ? (
      renderHeader(onClose)
    ) : (
      <div className={cls('FullScreenWrapper__header')}>
        <BackHeader
          onClick={() => {
            onClose();
            if (onCancel) {
              onCancel();
            }
          }}
          showClose={showCloseHeader}
          className={cls('FullScreenWrapper__backHeader', headerClassName)}
          title={headline || title}
          opacity={this.state.opacity}
        />
      </div>
    );
    return (
      <div className={cls('FullScreenWrapper')}>
        {header}
        <div
          className={cls(
            'FullScreenWrapper__content',
            footer && 'FullScreenWrapper__content--withFooter',
            contentClassName,
          )}
        >
          {title && (
            <BpkText
              tagName="h2"
              textStyle={TEXT_STYLES.subheading}
              className={cls('FullScreenWrapper__title')}
            >
              {title}
              <div
                className={cls('FullScreenWrapper__titleSpace')}
                ref={(el) => {
                  if (this.observer && el) {
                    this.observer.observe(el);
                  }
                }}
              />
            </BpkText>
          )}
          {children}
        </div>
        {footer && (
          <footer className={cls('FullScreenWrapper__footer', footerClassName)}>
            {footer}
          </footer>
        )}
      </div>
    );
  }
}

export default FullScreenWrapper;

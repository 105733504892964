import type { ComponentType } from 'react';

import { Portal } from '@skyscanner/backpack-web/bpk-react-utils';
import { withScrim } from '@skyscanner/backpack-web/bpk-scrim-utils';

import displayName from '../../display-name';

import withStackingContextReset from './withStackingContextReset';

type Props = {
  isOpen: boolean;
  onClose?: Function;
  containerClassName?: string;
  neverCloseFromPortal?: boolean;
  [key: string]: any;
};

const withScrimmedPortal = <P extends object>(
  WrappedComponent: ComponentType<P>,
) => {
  const getApplicationElement = () => document.getElementById('app-root');
  const Scrimmed = withScrim(withStackingContextReset(WrappedComponent));

  const component = ({
    containerClassName,
    isOpen,
    neverCloseFromPortal,
    onClose,
    ...rest
  }: Props) => (
    <Portal
      // @ts-ignore Property 'isOpen' does not exist, related to component Portal
      isOpen={isOpen}
      onClose={!neverCloseFromPortal ? onClose : () => {}} // To avoid unexpected close event in case multiple modals.
      target={getApplicationElement}
      closeOnEscPressed
    >
      <Scrimmed
        onClose={onClose}
        getApplicationElement={() => document.getElementById('app-root')}
        dark
        containerClassName={containerClassName}
        {...rest}
      />
    </Portal>
  );

  component.displayName = displayName(WrappedComponent);

  component.defaultProps = {
    containerClassName: null,
    neverCloseFromPortal: false,
  };

  return component;
};

export default withScrimmedPortal;
